<template>
  <div v-if="!isLoading">
    <main class="gate rally-gate">
      <form>
        <div
          v-if="rally.rally.attributes.privacy == 'Private'"
          class="rally-info hidden"
        >
          This Rally is Private.
        </div>
        <div
          v-else
          class="rally-info"
        >
          <img  class="avatar" :src="avatarImage" />
          <h1 class="title">
           {{ rally.rally.attributes.title }}
          </h1>
          <div class="cry">
            
          </div>
        </div>
        <div v-if="state == 'off-get-email'">
          <div class="form-group">
            <label for="email">Email Address</label>
            <input id="email" name="email" type="text" />
          </div>
          <div class="actions">
            <button
              class="primary"
              @click.prevent="lookForEmail"
            >
              Next
            </button>
          </div>
        </div>
        <div
          v-else-if="user.exists"
          class="welcome-back"
        >
          <hr />
          Welcome Back <b>{{ user.first_name }} {{ user.last_name }}</b>!
          <a
            class="link-button"
            @click.prevent="state = 'get-email'"
          >
            That's not me
          </a>
        </div>
        <div v-if="true || (state == 'get-password' || state == 'sign-up')">
          <div class="form-group half" v-if="false && !user.exists">
            <label for="first_name">First Name</label>
            <input id="first_name" name="first_name" type="text" />
          </div>
          <div class="form-group half" v-if="false && !user.exists">
            <label for="last_name">Last Name</label>
            <input id="last_name" name="last_name" type="text" />
          </div>
          <div v-if="false" class="form-group">
            <label for="password">Password</label>
            <input id="password" name="password" type="password" />
          </div>
          <div class="form-group" v-if="false && !user.exists">
            <label for="time_zone">Time Zone</label>
            <select id="time_zone" name="time_zone">
              <option
                v-for="timezone in timezones"
                :key="timezone"
                :label="timezone"
                :value="timezone"
              >
              </option>
            </select>
          </div>
          <div class="actions">
            <button
              v-if="isUserAMember"
              class="primary"
              @click.prevent="authenticate"
            >
              Log In
            </button>
            <button
              v-else-if="rally.rally.attributes.privacy === 'Public'"
              class="primary rally-join"
              @click.prevent="request"
            >
              Join Now
            </button>
            <button
              v-else-if="!isUserAMember && !requestedToJoin && !hasUserRequestedToJoin"
              class="primary rally-join"
              @click.prevent="request"
            >
              Request to Join
            </button>
            <div v-else-if="requestedToJoin || hasUserRequestedToJoin">
              Your request to join has been submitted. <br/>On approval, you will receive a confirmation email.
            </div>

           <div> <br/></div>
           <div > <a @click.prevent="navToRallies">BACK TO MY RALLYHOOD</a></div>
          </div>
        </div>
        <hr />
        <div v-if="false" class="trouble">
          <div>
            <router-link class="link-button" to="/login">Login</router-link>
            or
            <a class="link-button" href="mailto:support@rallyhood.com">Contact Us</a>
          </div>
        </div>
      </form>
    </main>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import { router, findDefaultChannelOfType, avaUrl} from "../common";
  import { userInfo } from "../common/user-info";
  import UserPermissionMixin from '../components/UserPermissionMixin.vue'; 
  export default {
    props: [ "uniqueId"],
    mixins: [UserPermissionMixin],
    data() {
      return {
        isLoading: true,
        requestedToJoin: false,
        myMembership: null,
        state: 'get-email',
        hasUserRequestedToJoin: false,
        timezones: [
          'Alaska',
          'Pacific',
          'Mountain',
          'Arizona',
          'Central',
          'Michigan',
          'East-Indiana',
          'Eastern',
        ],



        /* -----------------------------------------
           These mock up 10 potential paths:
           * hidden rally + I am an existing member
           * hidden rally + not a member + is public
           * hidden rally + not a member + not public
           * hidden rally + not a user + is public
           * hidden rally + not a user + not public
           * not hidden + I am an existing member
           * not hidden + not a member + is public
           * not hidden + not a member + is not public
           * not hidden + not a user + is public
           * not hidden + not a user + not public
           ... whew!
           -------------------------------------- */

       /* user: {
          exists: true,
          isMember: true,
          first_name: "Jason",
          last_name: "Topliff",
        },*/
        // -----------------------------------------
      };
    },
    computed: {
      ...mapState("rallies", ["allRalliesByRallyId","rallyChannelsByRallyId"]),
      ...mapState("contents", ["allContentByContextTypeAndId", "contentById", "defaultAvatarsByType"]),
      ...mapState("memberships", ["myMembershipByRallyId"]),
      ...mapState("user", ["status", "user", "usersById"]),
      currentUser(){
         return this.usersById[parseInt(userInfo().user_id)]
      },

      isUserAMember(){
        return (this.rally.membership && this.rally.membership.attributes.status !== 'Invited')
      },
      avatarImage() {
        return avaUrl(this.rallyAvatarContent) 
      },
      rallyAvatarContent() {
        var avid = this.rally.rally.relationships.avatar.data.id
        if ( avid == -1){
          return this.defaultAvatarsByType["rally"]
        } else {
        return this.contentById[avid];
        }
      },
      rallyId() {
        return this.$route.params.rallyId;
      },
      rally() {
        return this.allRalliesByRallyId[this.rallyId]
      },
      rallyChannels(){
        return this.rallyChannelsByRallyId[this.rallyId]
      },
      locked() {
        return !this.rally.membership && this.rally.rally.attributes.privacy !== 'Public'
      },
      hasAccessToAnyChannel(){
        var res = false;
        if (this.rallyChannels){ 
          this.rallyChannels.forEach(c => {
            if (c.attributes && (c.attributes.viewable || c.attributes.writable) ){
              res =  true
            }
          });
        }
        return res
      }


    },
    async created(){
      await this.fetchMembersByRally({rallyId: this.rallyId, status: undefined, fetchMyMemberInfo: true, role: undefined })
      this.myMembership = this.myMembershipByRallyId[this.rallyId];
      console.log("MY MEMBERSHIP", this.myMembership)
        if (this.myMembership && this.myMembership.attributes.status === 'Active'){
          //go to the rally
          router.push(`/${this.$route.params.rallyId}`)
          return 
        }

        if (this.myMembership && this.myMembership.attributes.status === 'Requested'){
          this.hasUserRequestedToJoin = true;
        }

        await this.fetchRalliesWithInfo({rallyId: this.rallyId});
        await this.fetchRalliesChannels({rallyId: this.rallyId});
        this.isLoading = false;
        /*setTimeout(() => {
           //if (!this.locked){  I don't think we want to check this because that would mean the public rally gate would never display
              if (this.rally.rally.attributes["child-count"] > 0){
                //TODO - This should navigate to the first channel that is viewable or writable
                //navigate to children
                this.fetchRalliesByParent({ rallyId: this.rallyId })
                router.push(`/${this.rallyId}/explore`);
              } else{
                router.push(`/${this.rallyId}/messages`);
              }
           //}
           this.isLoading = false;
        }, 2000); */
    },
    methods: {
      ...mapActions("memberships", ["createMemberInvite","acceptInvite", "fetchMembersByRally"]),
      ...mapActions("rallies", ["fetchRalliesWithInfo", "fetchRalliesByParent", "fetchRalliesChannels"]),
      authenticate() {
        //alert('Wee!!');
      },
      join() {
      
      },
      lookForEmail() {
        if ( this.user.exists ) {
          this.state = 'get-password';
        } else {
          this.state = 'sign-up';
        }
      },
      navToRallies(){
         this.$router.push("/rallies")
      },

      //Started POST "/api/v1/memberships.json" for 127.0.0.1 at 2022-04-28 15:55:02 -0500
      //Processing by Api::V1::MembershipsController#create as JSON
      //Parameters: {"data"=>{"type"=>"memberships", "attributes"=>{"rally_id"=>"38618", "role"=>"Friend", "status"=>"Requested", "created_via"=>"RequestToJoin", "author_id"=>303137, 
      //"sort_order"=>0, "user_id"=>303137, "first_name"=>"Jason", "last_name"=>"Topliff", "nickname"=>"", "email"=>"jason.topliff@rallyhood.com", "address"=>"", "city"=>"66086", 
      //"state"=>"", "zip"=>"", "country"=>nil, "primary_phone"=>"", "position"=>"testing", "facebook_url"=>"", "linkedin_url"=>"", "twitter_url"=>"", "expertise"=>"", 
      //"additional_info"=>"", "title"=>"Health Team", "rally_type"=>nil}}}

      request() {
        let stat = "Requested"
        if (this.rally.rally.attributes.privacy == 'Public'){
          stat = "Active"
        }
        this.createMemberInvite({ createdVia:"RequestToJoin", rallyId: this.rallyId, role: "Friend", status: stat, firstName: this.currentUser.attributes["first-name"], lastName: this.currentUser.attributes["last-name"], nickname: "", email: this.currentUser.attributes["email"], title: this.rally.rally.attributes.title})
        this.requestedToJoin = true

         if (this.rally.rally.attributes.privacy == 'Public'){
           this.$store.dispatch('user/flashSuccess', 'You Have Successfully Joined!');
            setTimeout(() => {
              if (this.rallyChannels) {
                this.rallyChannels.forEach(c => {
                  if (c.attributes && (c.attributes.viewable || c.attributes.writable)) {
                    this.$router.push(`/${this.rallyId}/channels/${c.id}`)
                  }
                });
              } else {
                this.$router.push(`/${this.rallyId}/messages`)
              }
            },1000)
         } else {
           this.$store.dispatch('user/flashSuccess', 'Your Request Has Been Sent!');
           this.$router.push("/rallies")
         }
      },
    }
  };
</script>
