<template>
  <article>
    <UserAvatar
      :pUserId="creatorId"
      p-font-size="1.2rem"
      p-line-height="2.5rem"
      pSize="35px">

    </UserAvatar>
    <a v-if="!isSharedFromAnotherRally"  v-on:click="$emit('perform', 'openContent', content)" class="title">{{
      content.attributes.title
    }}</a>
    <div v-if="isSharedFromAnotherRally"  class="title">
      * SHARED by {{sharedByName}} {{
      content.attributes.title
    }}</div>
    <div class="author-name">
      {{ byLine }}
    </div>
    <div class="created-at">
      {{ when }} : seen by {{ views }}
    </div>
    <div class="body">
      <div v-html="content.attributes.body"/>
      <ContentAttachments v-on:perform="perform" :readOnly="true" :content="content" :rallyId="parseInt(this.rallyId)" :channelId="parseInt(this.channelId)" />
    </div>
     <ContentPin :content="content"/>
     <ContentElipsisMenu :content="content" :rallyId="this.rallyId" :channelId="this.channelId" contentType="MessageWall" :canPin="isEditable" canShare="true"/>
    <Comments
      :shareId="content.relationships['context-share'].data.id"
      :avatarFile="content.attributes['creator-avatar']"
      :channelId="channelId"
      :rallyId="this.rallyId"
      v-if="currentChannel.attributes['allow-comments']"
    />
  </article>
</template>

<script>
import { Plugins } from "@capacitor/core";
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../../../common";
import { HB_LOGGING } from "../../../common";
import ContentMixin from "../../ContentMixin";
import Vue from 'vue';
import { globalVueRef } from "../../../main.js";

export default {
  name: "WallMessageListed",
  props: ["id", "channel", "content", "rally"],
  mixins: [ContentMixin],
  data() {
    return {
      page: 1,
      show_create: false,
      showElipsis: false,
      polling_int: null,
      newMessage: {
        title: null,
        body: null
      }
    };
  },
  computed: {
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("messages", ["allMessagesByRallyId"]),
    ...mapState("channels", ["channelsById"]),
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    ...mapState("shares", ["sharesByContentId"]),

    contentTitle(){
      return this.content.attributes.title
    },
    contentBody(){
      return this.content.attributes.body
    },
    creatorId() {
      return this.content.attributes['creator-id']
    },
    currentChannel() {
      return this.channelsById[this.channelId];
    },
    isEditable() {
      return this.content.attributes['editable'] === true;
    },
    rallyIdForUserAvatar(){
      if (this.ownerShare && this.ownerShare.attributes && this.ownerShare.attributes['context-rally-id']){
        return parseInt(this.ownerShare?.attributes['context-rally-id'])
      } else {
        return parseInt(this.rallyId)
      }
    },
  },
  created() {
    this.$route.params["pageNumber"] = 1;
    if (HB_LOGGING()){
      globalVueRef.$honeybadger.setContext({rally_id: this.rallyId}) 
    }
  },

  methods: {
    ...mapActions("messages", [
      "fetchAMessage",
      "createMessage",
    ]),
    /*userIsOwner(c){
      return (c && c.attributes && c.attributes["creator-id"] == this.user.user_id) 
    },*/
    hideActions() {
      this.showElipsis = false;
    },
    perform(method, params) {
      if ( this[method] ) {
        params ? this[method](params) : this[method]();
      } else {
        this.$emit('perform', method, params);
      }
    },
    formatDate(ds){
      return moment(ds).fromNow()
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
