<template>
  <div class="body">
    <form>
      <div class="form-group stacked-labels checkboxes">
        <div class="sub-heading">Notification Settings</div>
        <label>
          <input name="privacy" type="checkbox" v-model="receiveEmails"/>
          <div class="square"></div>
          Receive email notifcations from this rally.
        </label>
      </div>
      <div class="actions">
        <button class="primary" type="submit" @click.prevent="saveMemberNotifications()">Save Changes</button>
      </div>
    </form>
    <hr />
    <div v-if="canDelete">
      <div class="sub-heading">Manage Rally</div>
      <div class="block">
        <div class="description">This rally will no longer be accessible by any members.  Only you will be able to restore it.</div>
        <button class="warning" @click.prevent="confirmArchive()">Archive</button>
      </div>
      <hr />
      <div class="block">
        <div class="description">This rally will no longer be accessible by any members.  You will not be able to restore it.</div>
        <button class="danger" @click.prevent="confirmDelete()">Delete</button>
      </div>
      </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {router} from "@/common";

export default {
  name: "UserRallyPreferences",
  props: ['rallyId', 'isDesktopSize', 'canDelete'],
  data() {
    return {
      receiveEmails: null,
      myMembershipLoaded: false
    }
  },
  computed: {
    ...mapState("memberships", ["myMembershipByRallyId"]),
    ...mapState("rallies", ["allRalliesByRallyId"]),
    myMembership() {
      return this.myMembershipByRallyId[this.rallyId]
    },
    rally() {
      let r = this.allRalliesByRallyId[this.rallyId];
      return r
    },
  },
  async mounted() {
    await this.fetchMembersByRallyAndUserId({rallyId: this.rallyId}).then(() => {
      this.myMembershipLoaded = true;
      this.receiveEmails = !this.myMembership.attributes['disable-notifications'];
    });
  },
  methods: {
    ...mapActions("memberships", ["updateMemberNotifications", "fetchMembersByRallyAndUserId"]),
    ...mapActions("rallies", ["deleteRally", "updateRally"]),
    async archiveRally() {
      const rally = this.rally;
      // Call the updateRally action
      await this.updateRally({
        rally: rally,
        status: 'Archived'
      });
      this.$store.dispatch('user/flashSuccess', 'Rally successfully archived!');
      router.push("/rallies");
    },
    confirmArchive() {
      if (window.confirm("Are you sure you want to archive this rally?")) {
        this.archiveRally();
      }
    },
    confirmDelete() {
      if (window.confirm("Are you sure you want to delete this rally?")) {
        this.deleteThisRally();
      }
    },
    deleteThisRally() {
      this.deleteRally({rallyId: this.rallyId})
      this.$store.dispatch('user/flashSuccess', 'Rally successfully deleted!');
      router.push("/rallies");
    },
    saveMemberNotifications() {
      this.myMembership.attributes['disable-notifications'] = !this.receiveEmails
      this.updateMemberNotifications({member: this.myMembership, rallyId: this.rallyId})
      this.$store.dispatch('user/flashSuccess', 'Rally Notifications Successfully updated!');
    }
  }

}
</script>

<style scoped>

</style>