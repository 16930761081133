<template>
  <div style="background-color:#F5F5F5; height: 500px;">
    <div v-if="!loggedIn" class="ilogin">
      <router-link class="link-button" to="/login">Login</router-link>
    </div>
    <div class="gate rally-gate">
      <div class="gate-section">
        <div class="gate-header">
          <h2 style="text-align: center; font-size: 2rem;">{{ this.rallyTitle }}</h2>
          <h4>{{ this.email }}</h4>
        </div>
        <div v-if="!subscribed" class="gate-form">
          <form>
            <div v-if="!loggedIn">
              <label for="time_zone" style="padding-left: 25px; margin-bottom: 20px;">Please select your time zone</label>
              <select id="time_zone" name="time_zone" class="timezone" v-model="timezone">
                <option v-for="t in timezoneEntryList" :key="t[0]" :value="t[1]">{{t[0]}}</option>
              </select>
              <hr/>
              <label style="display:block; margin-bottom: 15px; margin-left:10px;">Yes, I'd like to join this rally.</label>
              <div class="form-group half" style="margin-left: 5px; margin-right: 5px;">
                <input id="first_name" name="first_name" type="text" placeholder="First Name" v-model="firstName" />
                <span v-if="submitted && !firstName" class="error">First Name is required</span>
              </div>
              <div class="form-group half">
                <input id="last_name" name="last_name" type="text" placeholder="Last Name" v-model="lastName"/>
                <span v-if="submitted && !lastName" class="error">Last Name is required</span>
              </div>
              <div class="form-group">
                <input id="password" name="password" type="password" placeholder="Password" class="password" v-model="password"/>
                <span v-if="submitted && !password" class="error">Password is required</span>
              </div>
            </div>
            <button
                type="button"
                @click="updateMembership('Active')"
                :disabled="isFormSubmitting"
                :class="isFormSubmitting ? 'dimmed' : 'primary'"
                style="width: 75%; margin-left:50px;"
            >
              JOIN THE RALLY
            </button>
            <div v-if="!loggedIn">
              <hr/>
              <label style="text-align: center; display:block; margin-bottom: 15px; margin-left:10px;">I'd like to follow emails for this rally but I'll create an account later to post content.</label>
              <button
                  type="button"
                  @click="updateMembership('Subscriber')"
                  :disabled="isFormSubmitting"
                  :class="isFormSubmitting ? 'dimmed' : 'hollow'"
                  style="width: 75%; margin-left:50px;"
              >
                FOLLOW
              </button>
            </div>
              <hr/>
              <label style="text-align: center; display:block; margin-bottom: 15px; margin-left:10px;">I <a href="#" @click.prevent="updateMembership('Declined')">DECLINE</a>
                the invitation and prefer not to receive emails from this rally. I can come back here if I change my mind.</label>

          </form>
        </div>
        <div v-else>
          <h3 style="text-align: center; margin-left: 25px; margin-right: 25px;">You are now following the emails from this rally. When you're ready to have full access to the rally, you'll be prompted to join.</h3>
          <hr style="margin-left: 25px; margin-right: 25px;"/>
          <button @click="goHome()" class="hollow" style="width: 65%; margin-left:75px; margin-bottom: 25px;">GOT IT!</button>
          <!-- Comment this out for now. The rally page is not yet visible for subscribers
            <button @click="goToRally()" class="primary" style="width: 65%; margin-left:75px; margin-bottom: 25px;">I'LL JOIN NOW FOR FULL ACCESS</button>
            -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {momentTzToRailsTz, railsTzToMomentTz, router, TIMEZONE_MAPPING} from "@/common";
import GateMixin from "@/components/GateMixin.vue";
import {userInfo} from "@/common/user-info";

export default {
  name: "InvitationGate",
  mixins: [GateMixin],
  data() {
    return {
      firstName: "",
      lastName: "",
      password: "",
      isFormSubmitting: false,
      loggedIn: false,
      rallyLoaded: false,
      rally: Object,
      membershipId: Number,
      submitted: false,
      subscribed: false,
      timezone: "",
      userId: Number,
    };
  },
  computed: {
    ...mapState("user", ["status", "user", "usersById"]),
    rallyTitle() {
      if (!this.rally) {
        return "";
      }
      return this.rally?.attributes?.title;
    },
    timezoneEntryList(){
      return Object.entries(TIMEZONE_MAPPING)
    },
  },
  async mounted() {
    this.loggedIn = userInfo();
    console.log("MOUNTED PUSH TO LOGIN")
    if (this.claimedUser && !userInfo()) {
      console.log("NOW PUSHING!!!!!!!")
      this.$router.push({
        path: '/login',
        query: { ...this.$route.query, rallyId: this.$route.params.rallyId }
      });
      return;
    }

    try {
      let rallyId =
          (this.$route.params.rallyId && !isNaN(this.$route.params.rallyId))
              ? this.$route.params.rallyId
              : this.$route.query.rallyId;

      let verifiedResponse = await this.verifyInvitationToken({invitationToken: this.invitationToken, rallyId: rallyId});
      if (!verifiedResponse || !verifiedResponse.membership_id ) {
        console.error("Invalid invitation token");
        this.$store.dispatch('user/flashFailure', `This invitation has already been accepted, is invalid, or has expired. `);
        // Add a delay to show the error before redirecting
        setTimeout(() => {
          this.$router.push({ name: 'rallies' });
        }, 2500);
      }
      this.membershipId = verifiedResponse.membership_id;
      this.userId = verifiedResponse.user_id;
      if(this.loggedIn && userInfo().user_id != this.userId) {
        console.error("Invitation token does not match the logged in user");
        this.$store.dispatch('user/flashFailure', `This invitation is for another user. `);
        // Add a delay to show the error before redirecting
        setTimeout(() => {
          this.$router.push({ name: 'home' });
        }, 2500);
      }
    } catch (e) {
      console.error("Invalid invitation token");
      this.$store.dispatch('user/flashFailure', `This invitation has already been accepted, is invalid, or has expired. `);
      // Add a delay to show the error before redirecting
      setTimeout(() => {
        this.$router.push({ name: 'home' });
      }, 2500);
    }

    this.rally = await this.fetchRallyGate({rallyId: this.$route.params.rallyId, invitationToken: this.invitationToken});
    this.rallyLoaded = true;

  },
  methods: {
    ...mapActions("memberships", ["acceptInvite", "acceptInviteForNewUser", "rejectInvite", "subscribeInvite", "verifyInvitationToken"]),
    ...mapActions("rallies", ["fetchRallyGate", "fetchMyRalliesWithInfo"]),
    ...mapActions("user", ["getAnonymousToken", "login", "resetPassword"]),

    goHome() {
      this.$router.push({name: 'home'});
    },
    goToRally() {
      let rallyId = this.$route.params.rallyId;
      this.$router.push({ name: 'Rally Page', params: { rallyId } });
    },
    async updateMembership(status) {
      this.isFormSubmitting = true;
      this.submitted = true;
      console.log(`Membership status updated to: ${status}`);
      let anonymousToken = await this.getAnonymousToken({ userId: this.userId, rallyId: this.rally.id, email: this.email})
      let member = {};
      if (this.loggedIn) {
        member = {
          id: this.membershipId,
          attributes: {
            status: status,
            user_id: this.userId,
          }
        };
      } else {
        member = {
          id: this.membershipId,
          attributes: {
            first_name: document.getElementById('first_name').value,
            last_name: document.getElementById('last_name').value,
            password: document.getElementById('password').value,
            status: status,
            timezone: this.timezone,
            user_id: this.userId,
          }
        };
      }
      switch (status) {
        case 'Active':
          if (this.firstName && this.lastName && this.timezone && this.password) {
            await this.acceptInviteForNewUser({member: member, rallyId: this.rally.id,  token: anonymousToken, invitationToken: this.invitationToken});
            if (!this.loggedIn) {
              await this.login({username: this.email.trim(), password: document.getElementById('password').value.trim()});
              this.$store.dispatch('user/flashSuccess', `You are now a member of ${this.rallyTitle}. Welcome to Rallyhood!`);
            } else {
              this.$store.dispatch('user/flashSuccess', `You are now a member of ${this.rallyTitle}.`);
            }
            await this.fetchMyRalliesWithInfo({});
            this.goToRally()
            break;
          } else {
            this.isFormSubmitting = false;
            this.$store.dispatch('user/flashFailure', `You must fill in all required fields.`);
            return
          }
        case 'Subscriber':
          await this.subscribeInvite({member: member, rallyId: this.rally.id, token: anonymousToken, invitationToken: this.invitationToken});
          this.$store.dispatch('user/flashSuccess', `You are now a subscribed to ${this.rallyTitle}.`);
          this.subscribed = true
          break;
        case 'Declined':
          await this.rejectInvite({member: member, rallyId: this.rally.id, token: anonymousToken, invitationToken: this.invitationToken});
          this.$store.dispatch('user/flashFailure', `Your invitation to ${this.rallyTitle} has been declined.`);
          router.push({name: 'home'});
          break;
        default:
          console.error(`Invalid status: ${status}`);
          break;
      }
      this.isFormSubmitting = false;
      // Add your logic here to handle the membership status update
    },
  },
}
</script>

<style scoped>
.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
}
.safearea {
  background-color: #F5F5F5;
}
.ilogin {
  position: relative;
  z-index: 200;
  float: right;
  margin-right: 50px;
  color: grey;
  margin-top: -50px;
}
.gate {
  position: fixed; /* Stay in place */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  z-index: 190;
}
.gate-header {
  padding: 10px;
  text-align: center;
}
.gate-section {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: #FFFFFF;
  margin-bottom: 30px;
  //margin-top: 50px;
  max-width: 445px;
  //padding-bottom: 25px;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.timezone {
  font-size: 1em;
  padding-left: 10px;
  height: 46px;
  line-height: 46px;
  width: 92%;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 25px;
  margin-top: 5px;
  display: block;
  height: 34px;
  padding: 6px 12px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.password {
  width: 94%;
  margin-left: 5px;
}
@media (min-width: 960px)
{
  .gate {
    z-index: 90;
  }
}
</style>