<template>
  <span>
    <li
      @click.stop="openModal"
      class="action"
    >
      <i class="fal fa-info-circle"></i>Details
    </li>
    <div :class="[{ open: modalStatus }, 'modal', 'details-modal']">
      <div class="shadow" @click="closeModal"></div>
      <div class="body">
        <i class="fal fa-times exit" @click="closeModal"></i>
   
        <div  class="loaded">
          <div class="heading">Details</div>
          <div class="info">
            Originally posted in
            <a class="link-button" v-if="this.originalRally" :href="originalRallyPath" >{{this.originalRally.rally.attributes.title}}</a>
            by {{ originalMessageCreatorFirstName }} {{ originalMessageCreatorLastName }} on {{this.doFormatDate(this.ownerSharedDate)}}
            <!--<div class="share-message">
              "Hey guys, this is a great resource that I thought you would enjoy!"
            </div>-->
          </div>
          <div class="heading" style="margin: 3rem 0 0;">SHARED</div>
          <div class="info" v-if="nonOwnerShares && nonOwnerShares.length > 0">
            <div v-for="s in nonOwnerShares"  :key="s.id">
            <table class="simple-table">
              <thead>
                <tr>
                  <th>By</th>
                  <th>To</th>
                  <th>On</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{getShareCreatorUser(s).attributes['first-name']}} {{getShareCreatorUser(s).attributes['last-name']}}</td>
                  <td>
                    <a class="link-button" :href="getShareRallyPath(s)" >{{getShareRally(s).rally.attributes.title}}</a>
                  </td>
                  <td>{{doFormatDate(getSharedDate(s))}} </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
          <div v-else class="info" style="font-style: italic; padding-top: 1rem;">
            This has not been shared outside of this Rally.
          </div>
        </div>
        <div class="actions">
          <button class="secondary" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import moment from "moment-timezone";
  export default {
    data: function() {
      return {
        modalStatus: false,
       // isLoading: true, 
        shared: false,
        sharesFetched: false
      };
    },
    async mounted(){

      await this.fetchSharesByContentId({contentId: this.content.id, contextRallyId: this.rallyId, contextChannelId: this.channelId})

      this.sharesFetched = true;
    },
    props: ["content", "rallyId", "channelId"],
    computed:{
        ...mapState("shares", ["sharesByContentId"]),
        ...mapState("contents", ["contentById"]),
        ...mapState("rallies", ["allRalliesByRallyId"]),
        ...mapState("user", ["status", "user", "usersById"]),
        originalMessageCreatorUser()
        {

          return this.usersById[parseInt(this.contentById[parseInt(this.ownerShares[0].attributes['content-uid'])].attributes['creator-id'])]
        },
        originalMessageCreatorFirstName(){
          if (!this.ownerShares || this.ownerShares.length == 0)
          {
            return "";
          }
          return this.originalMessageCreatorUser.attributes['first-name']
        },
        originalMessageCreatorLastName(){
          if (!this.ownerShares || this.ownerShares.length == 0)
          {
            return "";
          }
          return this.originalMessageCreatorUser.attributes['last-name']
        },
        isLoading(){
          return (this.ownerShares && this.ownerShares.length > 0  && this.originalRally && this.originalRally.rally)
        },
        originalRallyPath(){
          return '/rallies/' + this.originalRally.id
         // return '/' + this.originalRally.id + '/messages'
        },
        originalRally(){
          return this?.allRalliesByRallyId[parseInt(this.ownerShares[0]?.attributes['context-rally-id'])]
        },
        ownerSharedDate(){
          if (!this.ownerShares || this.ownerShares.length == 0)
          {
            return "";
          }
          return this.ownerShares[0].attributes['created-at']
        },

        ownerShares(){
          return this.contentShares ?  this.contentShares.filter(function(s) {return s.attributes['share-type'] == 'original'}) : []
        },
        nonOwnerShares() {
          return this.contentShares ? this.contentShares.filter(function(s) {return s.attributes['share-type'] !== 'original'}) : []
        },
        contentShares(){
          return this.sharesByContentId[parseInt(this.content.id)]
        },
    },
    methods: {      
      ...mapActions("shares", ["fetchSharesByContentId", "shareContent"]),
      getShareCreatorUser(s){
          return this.usersById[parseInt(this.contentById[parseInt(s.attributes['content-uid'])].attributes['creator-id'])]
      },
      getShareRally(s){
          return this.allRalliesByRallyId[parseInt(s.attributes['context-rally-id'])]  ?  this.allRalliesByRallyId[parseInt(s.attributes['context-rally-id'])] : {id: s.attributes['context-rally-id'], rally:{attributes:{title:s.attributes['context-rally-id']}}}
      },
      getSharedDate(s){
        return s.attributes['created-at']
      },
      getShareRallyPath(rally){
        return '/' + this.getShareRally(rally).id
      },
      doFormatDate(time) {
        return moment(time).format("MMMM, DD YYYY");
      },
      closeModal() {
        this.modalStatus = false;
      },
      openModal() {
        this.modalStatus = true;
      }
  }
  }
</script>
