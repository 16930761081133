<template>
  <span v-if="!this.isDeleted">
    <span
      v-if="this.readOnly && attachment.attributes['file-type'] == 'Photo'"
      v-on:click="
        $emit('perform', 'showLightbox', attachment)
      "
      :style="spanStyle"
      class="thumb"
      >
    </span>
    <div v-if="this.readOnly && attachment.attributes['file-type'] == 'File'">
      <a target="_blank" :href="attachment.attributes['original-url']">
        <i class="fal fa-file-image file-type attachment-icon"></i>
        <span class="attachment-file-title">{{ attachment.attributes.title }}</span>
      </a>
    </div>
    <div v-if="!readOnly" :class="['attached-file', { deleted: false }]">
      <i class="page-icon fa fa-file-o"></i>
      <span class="file-name">{{
        attachment.attributes["attachment-file-name"]
      }}</span>

      <!--<DeleteIcon  v-if="true" :content="attachment" :rallyId="rallyId"  :channelId="channelId"/>-->
      <select v-if="!readOnly" class="cc-to-container" v-model="attachment.copySettings">
        <option value="root">Copy to {{ containerChannel }}</option>
        <option
          v-for="container in containers"
          :key="container.id"
          :value="container.id"
        >
          Copy to "{{ container.attributes.title }}" {{ containerType }}
        </option>
        <option value="none">Do not copy</option>
      </select>
      <i class="delete-icon fa fa-times" @click="destroy"></i>
    </div>
  </span>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      containers: [],
      isDeleted: false
    };
  },
  props: [ "attachment", "readOnly", "newImageName", "rallyId", "channelId"],
  computed: {
    ...mapState("contents", ["allContentByContextTypeAndId"]),
    ...mapState("rallies", ["rallyChannelsByRallyId"]),
    isNew() {
      return (
        true //this.newImageName === this.attachment.attributes["attachment-file-name"]
      );
    },
    containerChannel() {
      return this.isPhoto ? "Gallery" : "Files";
    },
    containerType() {
      return this.isPhoto ? "album" : "folder";
    },
    isPhoto() {
      return this.attachment.attributes["file-type"] == "Photo";
    },
    spanStyle() {
      return (
        "background-color: " +
        this.attachment.attributes["background"] +
        "; background-image: url('" +
        this.attachment.attributes["medium-url"] +
        "');"
      );
    }
  },
  async mounted() {
    const channels = this.rallyChannelsByRallyId[this.rallyId]
    let containerChannelId = null
    channels.forEach(channel => {
      if (channel.attributes.type == this.containerChannel) {
        containerChannelId = channel.id
      }
    })

   /* await this.fetchContents({
      ignorePagination: true,
      contextRallyId: this.rallyId,
      contextType: 'Channel',
      contextId: containerChannelId,
      contextChannelId: containerChannelId,
      contentType: 'Container'

    }) */
    this.containers = await this.fetchFoldersRecursive({
      contextRallyId: this.rallyId,
      contextType: 'Channel',
      contextId: containerChannelId,
      contextChannelId: containerChannelId,
      nestLevel: 1,
      result: []
    })

  },
  methods: {
    ...mapActions("contents", ["flagContent", "fetchContents", "fetchFoldersRecursive"]),
    log(message) {
      this.$log.debug(message);
    },
    destroy() {
      if (confirm("Are you sure?")) {
        this.isDeleted = true;
        this.flagContent({ id: this.attachment.id, rallyId: this.rallyId });
      }
    }
  }
};
</script>
