<template>
  <article>
    <!-- Jason, this is the only "new" code, and should be based on the share message, not the original content -->
    <UserAvatar
        :pUserId="creatorId"
    ></UserAvatar>
    <a v-on:click="$emit('perform', 'openContent', content)" class="sharing title">

    </a>
    <div class="sharing shared-by">
      SHARED BY {{shareByName}} 
    </div>
    <div class="sharing created-at">
      {{ when }}
    </div>
    <div class="sharing body">
      {{ contextShare.attributes['share-message'] }}
    </div>
    <!-- end new code -->

    <!-- the original message code is now nested inside of this #original-content div, but is otherwise unchanged -->
    <div class="original-content">
      <span v-if="creatorId">
      <UserAvatar
        :pUserId="creatorId"
      ></UserAvatar>
      </span>
      <a v-on:click="$emit('perform', 'openContent', content)" class="title">{{
        content.attributes.title
      }}</a>
      <div class="author-name">
        {{ byLine }}
      </div>
      <div class="created-at">
        {{ when }}
      </div>
      <div class="body">
        <div v-html="content.attributes.body"/>
        <ContentAttachments v-on:perform="perform" :readOnly="true" :content="content" :rallyId="this.rallyId" :channelId="this.channelId" />
      </div>
      <ContentPin :content="content"/>
    </div>

    <!-- everything below this point is identical to wall_message/Listed.vue -->
    <ContentElipsisMenu :content="content"
                        :rallyId="this.rallyId"
                        :channelId="this.channelId"
                        contentType="MessageWall"
                        can-share="true"
                        can-pin="true"
                        can-unshare="true"/>
    <Comments
      :shareId="content.relationships['context-share'].data.id"
      :avatarFile="content.attributes['creator-avatar']"
      :channelId="channelId"
      :rallyId="this.rallyId"
      v-if="currentChannel.attributes['allow-comments']"
    />
  </article>
</template>

<script>
import { Plugins } from "@capacitor/core";
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
import { router } from "../../../common";
import ContentMixin from "../../ContentMixin";

export default {
  name: "WallMessageListed",
  props: ["id", "channel", "contextShare", "content", "rally"],
  mixins: [ContentMixin],
  data() {
    return {
      page: 1,
      show_create: false,
      showElipsis: false,
      polling_int: null,
      newMessage: {
        title: null,
        body: null
      }
    };
  },
  computed: {
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("messages", ["allMessagesByRallyId"]),
    ...mapState("channels", ["channelsById"]),
    ...mapState("contents", ["allContentByContextTypeAndId", "contentById"]),
    creatorId() {
      return this?.content?.attributes['creator-id']
    },
    currentChannel() {
      return this.channelsById[this.channelId];
    },
    isEditable() {
      return this.content.editable != 'true';
    },
    shareByName() {
      if (this.contextShare && this.contextShare.attributes){
        const attrs = this.contextShare.attributes;
        return `${attrs["sharer-first-name"]} ${attrs["sharer-last-name"]}`;
      } else {
        return ""
      }
    },
    when() {
      return this.contextShare && this.contextShare.attributes ? this.formatDate(this.contextShare.attributes["created-at"]) : "";
    }
  },
  created() {
    this.$route.params["pageNumber"] = 1;
  },

  methods: {
    ...mapActions("messages", [
      "fetchAMessage",
      "createMessage"
    ]),
    getAvatar() {
      this.contextShare.attributes['sharer-avatar']
    },
    hideActions() {
      this.showElipsis = false;
    },
    perform(method) {
      // Execute whatever method name came from the ToolBar.
      this[method]();
    },
    formatDate(ds){
      return moment(ds).fromNow()
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
