<script>
import { router, findDefaultChannelOfTypeForRally, findDefaultChannelObjOfType, avaUrl } from "../common";
import ContentFlagging from "./ContentFlagging";
import moment from "moment-timezone";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  components: {
    ContentFlagging
  },
  computed: {
    ...mapState("rallies", ["allRalliesByRallyId", "rallyChannelsByRallyId"]),
    ...mapState("memberships", ["myMembershipByRallyId"]),
    ...mapState("user", ["status", "user", "usersById"]),
    ...mapState("shares", ["sharesByContentId"]),
    isSharedFromAnotherRally(){
      if (this.ownerShare && this.ownerShare.attributes && this.ownerShare.attributes['context-rally-id']){
        return parseInt(this.ownerShare?.attributes['context-rally-id']) !== parseInt(this.rally.id)
      } else {
        return false
      }
    },
    ownerShare(){
      if (this.shares && this.shares.filter){
        return this.shares.filter(function(s) {
          return s.attributes['share-type'] == 'original'
        })[0];
      } else {
        return {}
      }
    },
    sharedByName(){
      if (this.originalCreatorUser && this.originalCreatorUser.attributes){
        return this.originalCreatorUser.attributes['first-name'] + " " + this.originalCreatorUser.attributes['last-name']
      } else {
        return "-"
      }
    },
    shares(){
      return this.sharesByContentId[parseInt(this.content.id)]
    },
    originalCreatorUser(){
      return this.usersById[parseInt(this.content.attributes['creator-id'])]
    },
    channelId() {
      return this.channel.id
    },
    rallyId() {
      return this.rally.id
    },
    selectedRally: function() {
      return this.allRalliesByRallyId[parseInt(this.$route.params.rallyId)];
    },
    myRole() {
      return this.selectedRally.membership ? this.selectedRally.membership.attributes["role"] : "Friend";
    },
    isAdmin() {
      // TODO: Rally admin should be able to see everyone's profile.
      return this.myRole === "Admin" || this.myRole === "Owner";
    },
    byLine() {
      if (this.content){
      const attrs = this.content.attributes;
      return `By ${attrs["creator-first-name"]} ${attrs["creator-last-name"]}`;
      } else {
        return ""
      }
    },
    myChannelSlug() {
      return window.location.href.split("/")[4];
    },

    userIsOwner(){
      return (this.content && this.content.attributes["creator-id"] == this.user.user_id) 
    },
    canDelete(){
       return ((this.content && this.content.attributes.deletable !== undefined) ?  this.content.attributes.deletable : (this.userIsOwner || this.isAdmin))
    },
    canEdit(){
      //return this.userIsOwner || this.isAdmin
      return ((this.content && this.content.attributes.editable !== undefined) ?  this.content.attributes.editable : (this.userIsOwner || this.isAdmin))
    },
    views() {
      if (this.content) {
        if (this.content.attributes["views"] != 'null') {
          return  Number(this.content.attributes["views"]).toLocaleString()
        } else {
          return 0
        }
      } else {
          return 0;
      }
    },
    when() {
      return this.content ? this.formatDate(this.content.attributes["created-at"]) : "";
    }
  },
  methods: {
    ...mapActions("contents", ["setContentById", "flagContent"]),
    // Execute whatever method name was emitted by a child component.
    formatDate(ds) {
      return moment(ds).fromNow();
    },

    callAvaUrl(c){
      return avaUrl(c)
    },
  
    // Content was just deleted or flagged as inappropriate.
    removeContent() {
      // TODO: Actually remove from some collection somewhere?
      console.log("called remove content")
      this.flagContent({ id: this.content.id, rallyId: this.rallyId });

    }
  }
};
</script>
