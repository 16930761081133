import axios from "axios";
import {
  router, 
  RCS_URL,
  RCS_PROMOTE_URL,
  RCS_SHARE_URL,
  buildServiceParamMap,
  mergeByIdMaintainOrder,
  mergeByAtt,
  compareBy,
  PARTS_URL,
  buildMapByValueName, 
  clearContentIdFromMapOfLists,
  figureOutPageNumber, 
  railsTzToMomentTz,
  generateCustomFieldJSON
} from "../../common";

import { userInfo } from "../../common/user-info"
import { authHeader } from "../../common/auth-header"
import moment from "moment-timezone";
import jwtInterceptor  from '../../common/jwt-interceptor';

const state = {
  allContentByContextTypeAndId: {},
  contentById: {},
  rsvpsByUserIdAndShareId: {},
  defaultAvatarsByType: {},
  resultCountByTypeAndId: {},
  maxPageByTypeAndId: {},
  contentSearchResults: [],
  noSearchResultsFlag: false,
  uploadCount: 0,
};

const getters = {

  rsvpsByUserIdAndShareId: state => {
      return state.rsvpsByUserIdAndShareId;
  },
  allContentByContextTypeAndId: state  => {
    //return sortByDate(state.allMessagesByRallyId[id], "attributes", "created-at")
    return state.allContentByContextTypeAndId;
  },
  contentById: state => {
    return state.contentById;
  },

  defaultAvatarsByType: state => {
    return state.defaultAvatarsByType;
  },

  resultCountByTypeAndId: state=> state.resultCountByTypeAndId,

  maxPageByTypeAndId: state=> state.maxPageByTypeAndId,

  contentSearchResults: state=> state.contentSearchResults,

  noSearchResultsFlag: state=> state.noSearchResultsFlag

};

const actions = {

  async clearContentsState({ commit, state }) {
    commit("clearState")
  },

  async clearContentByContextId({ commit, state }, inparams) {
    const { contextId } = inparams;
    //commit("clearContentItemFromContexts", {id: contextId})
    commit("clearContentByContextId", {contextId})
  },

  async createPromote({ commit, state }, inparams) {
    const { contentId, contentType, contextRallyId, contextChannelId } = inparams;
    const createdAttributes = {
      id: contentId,
      content_type: contentType,
      context_rally_id: contextRallyId,
      context_channel_id: contextChannelId
    }
    const requestOptions = {
      method: "POST",
      url: RCS_PROMOTE_URL(),
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "promotes",
          attributes: createdAttributes
        }
      }
    };
    const response = await jwtInterceptor(requestOptions);
    return response.data
  },

  async uploadFileMultipart({commit, state}, inparams){
      const { contextId, contextType, contextRallyId, contentType, type, rallyId, contextChannelId, attachment, attachmentContentType, attachmentFileName, tempKey, file } = inparams;
      const user = userInfo();
      const createdAttributes = {
        context_id: contextId,
        context_type: contextType,
        context_rally_id: rallyId,
        content_type: contentType,
        context_channel_id: contextChannelId,
        type,
        status: 1,
        include: 'creator,creator_avatar',
        creator_id:user.user_id,
        attachment,
        attachment_content_type: attachmentContentType,
        attachment_file_name: attachmentFileName
    };



      //let formData = new FormData();
      //formData.append("file", file);
      /*formData.append("context_id", contextId)
      formData.append("context_type", contextType)
      formData.append("context_rally_id", rallyId)
      formData.append("content_type", contentType)
      formData.append("context_channel_id", contextChannelId)
      formData.append("type", type)
      formData.append("status", 1)
      formData.append("include", 'creator,creator_avatar')
      formData.append("creator_id", user.user_id)*/
      const requestOptions = {
        method: "POST",
        url: RCS_URL(),
        headers: {
          "Content-Type": "multipart/form-data"
        },
       // formData,
       data: {
          data: {
            type: "contents",
            attributes: createdAttributes
          }
        }
      };
      const response = await jwtInterceptor(requestOptions);
      if (tempKey){
        this.dispatch("contents/setContentById", { id: response.data.data.id, content: response.data.data, tempKey });
      }
      this.dispatch("contents/fetchContents", inparams)

  },

//Parameters: {"data"=>{"type"=>"contents", "attributes"=>{"attachment"=>"[FILTERED]", "content_type"=>"Binary", "context_id"=>"306615", 
//"context_type"=>"Channel", "type"=>"Photo", "context_channel_id"=>"306615", "context_rally_id"=>"41405", "creator_id"=>303137, "status"=>1, "include"=>"creator,creator_avatar", "attachment_file_name"=>"[FILTERED]", "attachment_content_type"=>"[FILTERED]"}}}

//Started POST "/api/v1/contents.json" for 3.129.232.47 at 2022-06-20 15:57:07 +0000
//Processing by Api::V1::ContentsController#create as JSON
//Parameters: {"data"=>{"type"=>"contents", "attributes"=>{"attachment"=>"[FILTERED]", "creator_id"=>nil, "cropping"=>{"crop_h"=>"7", "crop_w"=>"20", "crop_x"=>"0", "crop_y"=>"10"}, "content_type"=>"Binary", "context_id"=>"51060", "context_type"=>"Rally", "status"=>1, "type"=>"RallyAvatar", "context_rally_id"=>"51060", "context_channel_id"=>nil, "attachment_file_name"=>"[FILTERED]", "attachment_content_type"=>"[FILTERED]"}}}

  async uploadFile({commit, state}, inparams) {
    const { contextId, contextType, contextRallyId, contentType, type, rallyId, contextChannelId, attachment, attachmentDataURL, attachmentContentType, attachmentFileName, tempKey, file } = inparams;
    const user = userInfo();
    const createdAttributes = {
        context_id: contextId,
        context_type: contextType,
        context_rally_id: contextRallyId,
        content_type: contentType,
        context_channel_id: contextChannelId,
        type,
        status: 1,
        include: 'creator,creator_avatar',
        creator_id:user.user_id,
        attachment,
        attachment_content_type: attachmentContentType,
        attachment_file_name: attachmentFileName
    };
    if (type == "RallyAvatar"){
     commit("setContentById", { id: contextRallyId + "_rally_avatar", content: {id: contextRallyId + "_rally_avatar" , attachmentDataURL }});
    }
    const requestOptions = {
      method: "POST",
      url: RCS_URL(),
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "contents",
          attributes: createdAttributes
        }
      }
    };
    let response = null;
    commit("incrementUploadCount", {})
    try {
      response = await jwtInterceptor(requestOptions);
    } catch (e){
      console.log(e)
    } finally {
      commit("decrementUploadCount", {})
    }
    
    if (response && response.data && response.data.data){
      response.data.data.attributes.editable = true
    }
    if (tempKey){
      //var contents = cleanObjects([response.data.data])
      //this.dispatch("contents/setContentById", { id: response.data.data.id, content: contents[0], tempKey });
      commit("setContentById", { id: response.data.data.id, content: response.data.data, tempKey });
      commit("setAllContentByContextTypeAndId", {isAttachments: true, contextType:"", contextId, contents: [response.data.data]});
    } else {
      commit("setContentById", { id: response.data.data.id, content: response.data.data });
      commit("setAllContentByContextTypeAndId", {isAttachments: true, contextType:"", contextId, contents: [response.data.data]});
    }
    //this.dispatch("contents/fetchContents", {...inparams,  contextRallyId: rallyId})

  },

  async createNotesAndInfoForStripeTx({ commit, state, getters, rootGetters}, inparams){
    const { stripeTransactionId, shareChannel, rallyId, customFields} = inparams;
    const user = userInfo();

    this.dispatch("contents/createContents", {
      contextId: stripeTransactionId,
      status: "1",
      contextType: "Payment",
      rallyId,
      contextChannelId: shareChannel,
      contentType: "Message",
      type: "RegistrationNotes",
      title: "Payment Notes",
      body: "",
      creator_id: user.user_id
    })
    this.dispatch("contents/createContents", {
      contextId: stripeTransactionId,
      status: "1",
      contextType: "Payment",
      rallyId,
      contextChannelId: shareChannel,
      contentType: "Message",
      type: "RegistrationExtraInformation",
      title: "Registration Extra Information",
      body: "",
      creator_id: user.user_id
    })
  
    if (customFields && customFields.length > 0){
      this.dispatch("contents/createContents", {
        contextId: stripeTransactionId,
        status: "1",
        contextType: "Payment",
        rallyId,
        contextChannelId: shareChannel,
        contentType: "Message",
        type: "PaymentCustomFields",
        title: "Custom Fields",
        body: generateCustomFieldJSON(customFields, []),
        creator_id: user.user_id
      })
    }
  },

  async updateNotesAndInfoForStripeTx({ commit, state, getters, rootGetters}, inparams){
    const { stripeTransactionId, shareChannel, rallyId, notes, addInfo, customFields} = inparams;
    const user = userInfo();
    const children = state.allContentByContextTypeAndId[stripeTransactionId]
    children.forEach(c => {
      if (c.attributes && c.attributes.type == "RegistrationNotes"){

        c.attributes.body = notes

        this.dispatch("contents/updateContent", {content: c, rallyId})
      } else if (c.attributes && c.attributes.type == "RegistrationExtraInformation"){

        c.attributes.body = addInfo

        this.dispatch("contents/updateContent", {content: c, rallyId})
      }
      else if (c.attributes && c.attributes.type == "PaymentCustomFields" && customFields){

        c.attributes.body = customFields

        this.dispatch("contents/updateContent", {content: c, rallyId})
      }
    });
  },

  //Started POST "/api/v1/contents.json" for 127.0.0.1 at 2021-03-11 20:51:39 -0600
  //Processing by Api::V1::ContentsController#create as JSON
  //Parameters: {"data"=>{"type"=>"contents", "attributes"=>{"context_id"=>"7891", "context_type"=>"Participant", "context_rally_id"=>"38720", "content_type"=>"Message", "type"=>"RegistrationExtraInformation", "title"=>"Payment Additional Information", "body"=>"my infoz", "creator_id"=>303137}}}
  async createContents({ commit, state, getters, rootGetters}, inparams) {
    const { status, contextChannelId, contextId, contextType, contextRallyId, contentType, type, title, body, rallyId,
      url, maxParticipants, description, tempKey, editMode, privacy, visibility} = inparams;
    const user = userInfo();
    var usersById = rootGetters['user/usersById']
    let visibilityParam = 'all'
    if (inparams.visibility){
      visibilityParam = visibility
    }
    const currentUser =  usersById[parseInt(user.user_id)]
    const createdAttributes = { 
        context_id: contextId,
        context_type: contextType,
        context_rally_id: rallyId,
        content_type: contentType,
        context_channel_id: contextChannelId,
        status,
        type,
        title,
        description,
        body,
        creator_id:user.user_id,
        url,
        visibility: visibilityParam
    };

    if (privacy){
      createdAttributes["privacy"] = privacy
    }
    /*if (content.attributes['show_names_to_admin'] === true ){
      createdAttributes['privacy'] = '1'
    }
    if (content.attributes['show_names_to_admin'] === false ){
      createdAttributes['privacy'] = '0'
    }*/
    if (type == "Event"){
      //we need a dummy date to start with or else we run into a an error on update the event later
      //the use case is that we create a draft event before the user starts entering 
      createdAttributes["all_day"] = '1'
      createdAttributes["to_date"] = moment().format("YYYY-MM-DD")
      createdAttributes["from_date"] = moment().format("YYYY-MM-DD")
      createdAttributes['from_time'] = '12:00 PM'
      createdAttributes['to_time'] = '01:00 PM'
      createdAttributes["status"] = '1'
    }
    if (maxParticipants){
      createdAttributes["max_participants"] = maxParticipants
    }
    const requestOptions = {
      method: "POST",
      url: RCS_URL(),
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "contents",
          attributes: createdAttributes
        }
      }
    };
    const response = await jwtInterceptor(requestOptions);
    if (currentUser) {
      response.data.data.attributes["creator-first-name"] = currentUser.attributes["first-name"]
      response.data.data.attributes["creator-last-name"] = currentUser.attributes["last-name"]
    }
    //we don't have the real info from the backend yet but when we are creating it then its editable and deletable by us...
    if (response && response.data && response.data.data && response.data.data.attributes){
      response.data.data.attributes["editable"] = true
      response.data.data.attributes["deletable"] = true
    }
    if (type == "PrivateMessage"){
      response.data.data.privateMessageChannel = {"id": contextChannelId, "attributes": {"rally-id": rallyId}}
    }
    if (editMode && response.data.data){
      response.data.data.editMode = true
    }
    if (tempKey && response.data.data){
      response.data.data.tempKey = tempKey
    }

    commit("setContentById", { id: response.data.data.id, content: response.data.data, tempKey });
    commit("setAllContentByContextTypeAndId", {prefix: true, contextType:"", contextId, contents: [response.data.data]});
    //this.dispatch("contents/fetchContents", {...inparams,  contextRallyId: null})
  },

  async flagContent({ commit, state }, inparams) {
    const { id, rallyId } = inparams;
    const user = userInfo();
    const createdAttributes = {
        id,
        context_rally_id: rallyId,
        status: 2,
    };
    const requestOptions = {
      method: "PUT",
      url: RCS_URL() + "/" + id + ".json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "contents",
          attributes: createdAttributes
        }
      }
    };
    const response = await jwtInterceptor(requestOptions);
    commit("setContentById", {id, content:null})
    commit("clearContentItemFromContexts", {id})
  },

  async sortContent({ commit, state }, inparams) {
    const { id, rallyId, sortOrder } = inparams;
    const user = userInfo();
    const createdAttributes = {
      id,
      context_rally_id: rallyId,
      sort_order: sortOrder,
    };
    const requestOptions = {
      method: "PUT",
      url: RCS_SHARE_URL() + id,
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "shares",
          attributes: createdAttributes
        }
      }
    };
    const response = await jwtInterceptor(requestOptions);
    //commit("setContentById", {id: id, content: response.data.data})
    //commit("setContentById", {id, content:null})
    //commit("clearContentItemFromContexts", {id})
  },

  async deleteContent({ commit, state }, inparams) {
    const { id, rallyId } = inparams;
    const user = userInfo();
    var jsonparams = {
      id,
      context_rally_id: rallyId
    }

    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "DELETE",
      url: RCS_URL() + "/" + id + ".json",
      headers: {
        "Content-Type": "application/json"
      },
    };
    const response = await jwtInterceptor(requestOptions);
    commit("setContentById", {id, content:null})
    commit("clearContentItemFromContexts", {id})
  },

  /*Parameters: {"utf8"=>"✓","content"=>{"status"=>"1", "timezone"=>"Central Time (US & Canada)", "title"=>"JT April 20 Event 1", 
  "location"=>"my location", "online_location"=>"", "online_location_password"=>"[FILTERED]", "description"=>"", 
  "color"=>"#1c8291", "rsvp"=>"1", "all_day"=>"0", "from_date"=>"04/20/2022", "from_time"=>"01:00 PM", "to_time"=>"02:00 PM", 
  "to_date"=>"04/20/2022", "repeat_frequency"=>"month", "repeat_interval"=>"1", "repeat_end_date"=>"04/30/2022", 
  "repeat_monthly_weeks"=>"1", "repeat_monthly_days"=>"0"}, "override_url"=>"/38720/events", "option_month"=>"day_option", 
  "commit"=>"Save", "rally_id"=>"38720", "channel_id"=>"events", "id"=>"19025738"}*/
  async updateContent({ commit, state }, inparams) {
    const { content, rallyId} = inparams;
    const user = userInfo();
    let updateDate = true
    if (inparams && inparams.noDateUpdate){
      updateDate = false
    }

    const createdAttributes = {
        id: content.id,
        content_type: content.attributes['content-type'],
        context_rally_id: rallyId,
        creator_id:user.user_id,
    };


    if (content.attributes['show_names_to_admin'] === true ){
      createdAttributes['privacy'] = '1'
    }
    if (content.attributes['show_names_to_admin'] === false ){
      createdAttributes['privacy'] = '0'
    }
    if (content.attributes['max-participants']){
      createdAttributes['max-participants'] = content.attributes['max-participants'] 
    }
    if (content.attributes['location']){
      createdAttributes['location'] = content.attributes['location']
    }
    if (content.attributes['online_location']){
      createdAttributes['online-location'] = content.attributes['online_location']
    }
    if (content.attributes['online_location_password']){
      createdAttributes['online_location_password'] = content.attributes['online_location_password']
    }

    if (content.attributes['status'] || content.attributes['status'] == 0){
      createdAttributes['status'] = content.attributes['status']
    }
    if (content.attributes['rsvp']){
      createdAttributes['rsvp'] = content.attributes['rsvp']
    }
    if (content.attributes['description']){
      createdAttributes['description'] = content.attributes['description']
    }

    if (updateDate){
      if (content.attributes['repeat-interval']) {
        createdAttributes['repeat-interval'] = content.attributes['repeat-interval']
      }
  
      if (content.attributes['repeat-frequency']){
        createdAttributes['repeat-frequency'] = content.attributes['repeat-frequency']
      }
  
      if (content.attributes['repeat-end-date']){
        createdAttributes['repeat-end-date'] = content.attributes['repeat-end-date']
        if (content.attributes['repeat-monthly-weeks']){
          createdAttributes['repeat-monthly-weeks'] = content.attributes['repeat-monthly-weeks']
        }
        if (content.attributes['repeat-monthly-days']){
          createdAttributes['repeat-monthly-days'] = content.attributes['repeat-monthly-days']
        }
        if (content.attributes['repeat-weekly-day']){
          createdAttributes['repeat-weekly-day'] = content.attributes['repeat-weekly-day']
        }
      }
      if(content.attributes['update-all']) {
        createdAttributes['update_all'] = content.attributes['update-all']
      }
      var allDay = content.attributes['all-day'] && content.attributes['all-day'] == 1 ? true : false
      createdAttributes['all-day'] =  content.attributes['all-day'] && content.attributes['all-day'] == 1 ? '1' : '0'
      //"all_day"=>"0", "from_date"=>"01/30/2022", "from_time"=>"05:00 PM", "to_time"=>"06:00 PM", "to_date"=>"01/30/2022"
      if (content.attributes['from-date']){
        createdAttributes['from-date'] = content.attributes['from-date'] 
        createdAttributes['timezone'] =  railsTzToMomentTz(userInfo().timezone)
        if (!content.attributes['title'] ){
          //no title setting status to pending
          createdAttributes['status'] = 0
        }
      }
      // createdAttributes['from_time'] = '12:00 PM'
      // createdAttributes['to_time'] = '01:00 PM'
      if (content.attributes['from-time'] && !allDay){
        createdAttributes['from-time'] = content.attributes['from-time']
      }
      if (content.attributes['to-date']){
        createdAttributes['to-date'] = content.attributes['to-date'] 
      }
      if (content.attributes['to-time'] && !allDay){
        createdAttributes['to-time'] = content.attributes['to-time']
      }
    }
    if (content.attributes['participant-count']){
      createdAttributes['participant-count'] = content.attributes['participant-count']
    }
    if (content.attributes['title']){
      createdAttributes['title'] = content.attributes['title']
    }
    if (content.attributes['url']){
      createdAttributes['url'] = content.attributes['url']
      
    }
    if (content.attributes['body']){
      createdAttributes['body'] = content.attributes['body']
    }
    if (content.attributes['color']){
      createdAttributes['color'] = content.attributes['color']
    }

    //we don't have the real info from the backend yet but when we are creating it then its editable and deletable by us...
    if (content.attributes) {
      content.attributes["editable"] = true
      content.attributes["deletable"] = true
    }
    const requestOptions = {
      method: "PUT",
      url: RCS_URL() + "/" + content.id + ".json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "contents",
          attributes: createdAttributes
        }
      }
    };
    //if (response.data.data.attributes){
      //response.data.data.attributes.body = content.attributes['body']
    //}
    const response = await jwtInterceptor(requestOptions);
    if (content.attributes && response.data.data){
      if (content.attributes["creator-first-name"] && content.attributes["creator-last-name"]){
        response.data.data.attributes["creator-first-name"] = content.attributes["creator-first-name"]
        response.data.data.attributes["creator-last-name"] = content.attributes["creator-last-name"]
      }
      
      response.data.data.attributes.editable =  content.attributes.editable
      response.data.data.attributes.deletable =  content.attributes.deletable
    }
    commit("setContentById", {id: content.id, content: response.data.data})
    //this.dispatch("messages/fetchAllMessages", inparams);
  },

  async moveContent({ commit, state }, inparams) {
    const { shareId, destinationId, destinationType} = inparams;
    const user = userInfo();

    const moveAttributes = {
      context_id: destinationId,
      context_type: destinationType,
    };

    const requestOptions = {
      method: "PUT",
      url: RCS_SHARE_URL() + shareId,
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "shares",
          attributes: moveAttributes
        }
      }
    };

    const response = await jwtInterceptor(requestOptions);
  },


  //https://internal-rcs.rallyhood.com:443/api/v1/contents.json?content_type%5B%5D=Attachment&content_type%5B%5D=Binary&content_type%5B%5D=Container&context_channel_id=630912&context_id=15248127&context_type=Container&include=creator%2Ccreator_avatar&page%5Bnumber%5D=2&page%5Bsize%5D=12&query=&sort=content_type+desc%2Ccontents.id&status=1&visibility%5B%5D=all&visibility%5B%5D=wall_only 
  //this.fetchContents({rallyId: this.rallyId, contextType: "Channel", contextChannelId: 294216, contextId:this.rallyId, contentType: ['Attachment', 'Binary', 'Container'], pageNumber: 1});
  async fetchContents({ commit, state, getters, rootGetters }, inparams) {
    const { query, ignorePagination, contextRallyId, contextType, contextId, contentType, contextChannelId, visibility,
      status, type, participationStatus, participantId, participantType, childContentParticipantId, userId, privateMessageChannel, privateMessageRecipient,
      creatorId, customPageSize, excludeParticipations} = inparams;
    var usersById = rootGetters['user/usersById']
    let includeParam = "shares,creator,creator_avatar,participations,avatar,views"
    if(excludeParticipations || contentType == "Message" || contentType == "File"){
      includeParam = "shares,creator,creator_avatar,avatar,views"
    }

    const user = userInfo();
    var pageNumber = figureOutPageNumber(state.maxPageByTypeAndId[contextId])
    if (ignorePagination){
      pageNumber = 1
    }
    //https://api-gateway.rallyhood.com/content/contents?context_type=Container&context_channel_id=630912&page%5Bnumber%5D=1&page%5Bsize%5D=12&sort=content_type+desc%2Ctitle&include=shares%2Ccreator%2Ccreator_avatar%2Cparticipations%2Cavatar%2Cviews&user_id=347216&content_type%5B%5D=Binary&content_type%5B%5D=Link&content_type%5B%5D=Container&content_type%5B%5D=Event&timezone=Central+Time+%28US+%26+Canada%29&context_id=15248127
    //url[http://localhost:3020/api/v1/contents.json?content_type%5B%5D=Attachment&content_type%5B%5D=Binary&content_type%5B%5D=Container&context_channel_id=290813&context_id=290813
    //&context_rally_id=38720&context_type=Channel&include=creator%2Ccreator_avatar%2Cshares&page%5Bnumber%5D=1&page%5Bsize%5D=12&query=test&sort=content_type+desc%2Ctitle
    //&status=1&visibility%5B%5D=all&visibility%5B%5D=wall_only],
    let visibilityParam = ['all','wall_only']
    if (inparams.visibility){
      visibilityParam = visibility
    }
    var jsonparams = {
      context_rally_id: contextRallyId,
      context_type: contextType,
      context_channel_id: contextChannelId,
      "page[number]": pageNumber,
      "page[size]": customPageSize ? customPageSize : 10,
      "sort":  (contentType == "Message" || contentType == "File") ? "content_type,shares.sort_order,shares.start_date desc,shares.created_at desc" : "content_type desc,title",
      include: includeParam,
      user_id: userId ? userId : user.user_id,
      visibility: visibilityParam,
      content_type: contentType,
      status,
      type,
      creator_id: creatorId,
      timezone_name: user.timezone,
      participation_status: participationStatus,
      participant_id: participantId,
      participant_type: participantType,
      child_content_participant_id: childContentParticipantId,
      friendly_repeat_summary: true
    };

    if (contextId){
      jsonparams["context_id"] = contextId
    }
    if (query){
      jsonparams["query"] = query
      commit("setNoSearchResultsFlag", {flag: false})
    }


    try{
      var params = buildServiceParamMap(jsonparams);

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
        url: RCS_URL() ,
        params: params
      };
      const response = await jwtInterceptor(requestOptions);

      if (!ignorePagination){
        commit("setResultCountByTypeAndId", { typeAndId: contextId, count: response.data.meta["total-count"] });
      }

      if (response.data.data && privateMessageChannel){
        //attach each private message content with its channel info
        response.data.data.forEach(c => {
          c['privateMessageChannel'] = privateMessageChannel
        });
      }

      if (response.data.data && privateMessageRecipient){
        //attach each private message content with its channel info
        response.data.data.forEach(c => {
          c['privateMessageRecipient'] = privateMessageRecipient
        });
      }

      if (!query){
        commit("setAllContentByContextTypeAndId", { pageNumber, contextType:"", contextId, contents: response.data.data});
        if (response.data.data && response.data.data.forEach) {
              response.data.data.forEach(c => {
                if (c){
                  commit("setContentById", { id: c.id, content: c})
                }

              })
              if (response.data.included){
                this.dispatch("contents/setIncludedById", {included: response.data.included})
              }
          }
      } else {
        commit("setContentSearchResults", { contents: response.data.data});
        if (!response.data.data || response.data.data.length == 0){
          commit("setNoSearchResultsFlag", {flag: true})
        }
      }

      try {
        if (response.data.data && response.data.data.attributes['creator-id']){
          let u = usersById[response.data.data.attributes['creator-id']]
          response.data.attributes["creator-first-name"] = u.attributes['first-name']
          response.data.attributes["creator-last-name"] = u.attributes['last-name']
        }
      }
      catch (e){
        console.log("no creator id set")
      }
      commit("setContentsById", {contents: response.data.data})

      if (response.data.data && response.data.data.forEach) {
        response.data.data.forEach(c => {
          let cuser = {attributes:{}}
          cuser.attributes["first-name"] = c.attributes["creator-first-name"]
          cuser.attributes["last-name"] = c.attributes["creator-last-name"]
          cuser.attributes["avatar"] =  c.attributes["creator-avatar"]
          cuser.id = c.attributes["creator-id"] + ""
          this.dispatch("user/setUsersById", { users: [cuser]});
        })
      }

      if (response.data.included){
        this.dispatch("contents/setIncludedById", {included: response.data.included})
      }
      if (contentType == "Payable" && response.data && response.data.data.length && response.data.data.length > 0){
        if (response.data.data[0] && response.data.data[0].attributes['account-rally-id'])
          this.dispatch("rfins/fetchAccount", {accountRallyId: response.data.data[0].attributes['account-rally-id'] })
      }

    } catch (e){
      console.log("fetch contents error")
      console.log(e)
      if ((e + " ").indexOf("401") > 0){
        localStorage.removeItem("at")
        await Storage.remove({ key: 'user' });
        router.push(`/login`); 
        location.reload()
      }
    }
  },

  async fetchFoldersRecursive({ commit, state, dispatch }, { contextRallyId, contextChannelId, contextId, contextType, nestLevel, result }) {
    // Make an API call to fetch the folders
    const response = await jwtInterceptor({
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RCS_URL(),
      params: buildServiceParamMap({
        include: "shares,creator,creator_avatar",
        content_type: 'Container',
        context_channel_id: contextChannelId,
        context_id: contextId,
        context_type: contextType,
        sort: 'title'
      })
    });

    const folders = response.data.data;
    const included = response.data.included;

    // Process each folder
    for (const folder of folders) {
      // Add the nest level attribute to the folder object
      folder.attributes['nest-level'] = nestLevel;
      // Set parent ID if contextType is 'Container'
      if (contextType === 'Container') {
        folder.parentId = contextId;
      }
      // Add the current folder to the result array
      result.push(folder);
      // Recursively fetch the nested folders
      await dispatch("fetchFoldersRecursive", {
        contextChannelId,
        contextId: folder.id,
        contextType: 'Container', // Always 'Container' for nested folders
        nestLevel: nestLevel + 1,
        result: result
      });
    }

    // Return the accumulated result
    return result;
  },

  async fetchContentById({ commit, state }, inparams) {
    const user = userInfo();
    const { id, contentType, contextChannelId, contextRallyId} = inparams;

    var jsonparams = {
      id,
     // context_channel_id: contextChannelId,
      include: "shares,creator,creator_avatar,avatar,participations",
      content_type: contentType,
      context_rally_id: contextRallyId,
      context_channel_id: contextChannelId,
      //use_event_timezone: "true",
      timezone: user.timezone, 
      user_id: user.user_id,
      friendly_repeat_summary: true
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RCS_URL() + "/" + id ,
      params: params
    };
    const response = await jwtInterceptor(requestOptions);

    //commit("setAllContentByContextTypeAndId", { contextType: "", contextId:contextChannelId, contents: response.data.data});
    if (response.data.data){
      commit("setContentById", { id, content: response.data.data});
    }
    if (response.data.included){
      this.dispatch("contents/setIncludedById", {included: response.data.included})
    }
  },



  async fetchRSVP({ commit, state }, inparams) {
    const { contentUid, shareId} = inparams;
    const user = userInfo();

    var jsonparams = {
      content_uid: contentUid,
      share_id: shareId,
      user_id: user.user_id
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: PARTS_URL(),
      params: params
    };

    const response = await jwtInterceptor(requestOptions);
    commit("setRsvpsByUserIdAndShareId", {userId: user.user_id, shareId, content: response});
  },

  async doRSVP({ commit, state}, inparams) {
  const { contentUid, shareId, quantity, status, rsvpStatus, id} = inparams;
  const user = userInfo();
  const createdAttributes = {
    content_uid: contentUid,
    share_id: shareId,
    orgination: "opt_in",
    originator_id: user.user_id,
    originator_type: "User",
    participation_status: status,
    rsvp_status: rsvpStatus,
    participant_type: status,
    user_id: user.user_id,
    quantity, 
    id
  };

  const requestOptions = {
    method: "POST",
    url: PARTS_URL(),
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      data: {
        type: "participations",
        attributes: createdAttributes
      }
    }
  };

  //commit("setAllMessages", [{id:123, message: {attributes: createdAttributes}}]);
  const response = await jwtInterceptor(requestOptions);
  this.dispatch("participations/setParticipationById", {id: response.data.data.id, participation: response.data.data});
  //this.dispatch("messages/fetchAllMessages", inparams);
},

async setContentsById({ commit, state }, inparams) {
  commit("setContentsById", inparams)
},




async setContentById({ commit, state }, inparams) {
  commit("setContentById", inparams)
},

async clearContentItemFromContexts({ commit, state }, inparams) {
  commit("clearContentItemFromContexts", inparams)
},

async setAllContentByContextTypeAndId({ commit, state }, inparams) {
  commit("setAllContentByContextTypeAndId", inparams)
},

async setContentSearchResults({commit, state}, inparams) {
  commit("setContentSearchResults", inparams)
},

async clearContentSearchResults({commit, state}, inparams) {
  commit("setContentSearchResults", { contents: []})
},

async setNoSearchResultsFlag({commit, state}, inparams) {
  commit("setNoSearchResultsFlag", inparams)
},

async setIncludedById({commit, state}, inparams){
  const {included, avatarType} =  inparams;
  if (included && included.forEach) {
      included.forEach(inc => {
        if (inc.type == "participations") {
          this.dispatch("participations/setParticipationById", {id: inc.id, participation: inc})
        } 
        else if (inc.type == "users") {
          this.dispatch("user/setUsersById", { users: [inc]});
        } 
        else if (inc.type == "shares") {
          this.dispatch("shares/setSharesByContentId", {id: inc.attributes["content-uid"], shares: [inc]})
          this.dispatch("shares/setSharesByShareId", { share: inc})
        } 
        else if (inc.type == "membership-profiles") {
          this.dispatch("memberships/setProfileByMembershipId", { profile: inc});
        } 
        else {
          if (inc.id === "-1" && avatarType && inc.type === "avatars"){
           commit("setDefaultAvatarsByType", {avatarType, content: inc})
          } else {
            commit("setContentById", { id: inc.id, content: inc});
          }
        }
      })
    }
  }
};

const mutations = {
  //clearAllMessages: state => (state.allMessages = []),
  incrementUploadCount: ( state ) => {
    console.log("inc..." + state.uploadCount)
    if (!state.uploadCount || state.uploadCount == 0) {
      state.uploadCount = 1
    } else {
      state.uploadCount = state.uploadCount + 1
    }
  },
  
  decrementUploadCount: ( state ) => {
    console.log("dec..."+ state.uploadCount)
    if (!state.uploadCount || state.uploadCount <= 1) {
      state.uploadCount = 0
    } else {
      state.uploadCount = state.uploadCount - 1
    }
  },
  
  clearState: (state) => {
    state.allContentByContextTypeAndId= {} 
    state.contentById = {}
    state.rsvpsByUserIdAndShareId = {}
    state.contentSearchResults = []
  },
  clearContentByContextId: (state, params) => {
    const {contextId} = params
    let byIdObj = {}
    byIdObj[contextId] = [] 
    state.allContentByContextTypeAndId[contextId] = []
    state.allContentByContextTypeAndId = {...state.allContentByContextTypeAndId, ...byIdObj}

    let newMap = {};
    newMap[contextId]= null
    state.resultCountByTypeAndId = {...state.resultCountByTypeAndId, ...newMap}

    newMap = {}
    newMap[contextId]= []
    state.maxPageByTypeAndId = {...state.maxPageByTypeAndId, ...newMap}
  },
  setDefaultAvatarsByType: (state, params) => {
    const {avatarType, content} = params
    var byIdObj = {}
    byIdObj[avatarType] = content 
    state.defaultAvatarsByType = {...state.defaultAvatarsByType, ...byIdObj}
  },
  setRsvpsByUserIdAndShareId: (state, params) => {
    const {userId, shareId, content} = params
    var byIdObj = {}
    byIdObj[userId+shareId]= content
    state.rsvpsByUserIdAndShareId = {...state.rsvpsByUserIdAndShareId, ...byIdObj};
  },
  setContentById: (state, params) => {
    const {id, content, tempKey} = params

    var byIdObj = {}
    byIdObj[id + ""]= content
    if (tempKey){
      byIdObj[tempKey] = content
    }
    state.contentById = {...state.contentById, ...byIdObj};
  },
  setContentsById: (state, params) => {
    const {contents} = params 
    state.contentById = {...state.contentById, ...buildMapByValueName("id", contents)};
  },
  clearContentItemFromContexts: (state, params) => {
    const {id} = params
    state.allContentByContextTypeAndId = { ...clearContentIdFromMapOfLists(state.allContentByContextTypeAndId, id)}
  },
  setAllContentByContextTypeAndId: (state, params) => {
    const {pageNumber, contextId, contextType, contents, isAttachments, prefix= false} = params
    var typeAndId = contextId 
    var curarr = state.allContentByContextTypeAndId[typeAndId];
    if (!curarr) {
      curarr = [];
    }

    var theContents = contents ? contents : []  
    //the original ordering of this breaks long paginations on message wall etc... mergeByIdMaintainOrder(theContents, curarr, "id");
    let newarr = []

     if (prefix){
      newarr = mergeByIdMaintainOrder(theContents, curarr, "id");
    } else {
      newarr = mergeByIdMaintainOrder(curarr, theContents, "id");
    }

    //let ctype = theContents.length > 0 && theContents[0].attributes ? theContents[0].attributes.type : ""
    //console.log("ctype:"+ ctype)
    //if ( ctype == "File" || ctype == "Folder") {
      //newarr = theContents
    //}

    if (isAttachments){
      newarr = mergeByAtt(theContents, newarr, "file-name");
    }

    //this sorts the new file elements to the top of the list of files and not the top of the list
    newarr = newarr.sort(compareBy({"Folder":"a", "File":"b", "Attachment": "b", "Album": "a", "Photo":"b"}, "type"))

    //this so
    var byIdObj = {};
    byIdObj[typeAndId] = newarr
    state.allContentByContextTypeAndId = { ...state.allContentByContextTypeAndId, ...byIdObj };
    //track pagination numbers
    var newMap = {};
    var calledPages = state.maxPageByTypeAndId[typeAndId]
    if (!calledPages){
      calledPages = []
    }
    calledPages[pageNumber] = "called"
    newMap[typeAndId]= calledPages
    state.maxPageByTypeAndId = {...state.maxPageByTypeAndId, ...newMap}
  },

  setResultCountByTypeAndId: (state, params) =>{
    const {typeAndId, count} = params;
    var newMap = {};
    newMap[typeAndId]= count
    state.resultCountByTypeAndId = {...state.resultCountByTypeAndId, ...newMap}
  },

  setContentSearchResults: (state, params) => {
    const { contents } = params;
    state.contentSearchResults = contents
  },
  setNoSearchResultsFlag: (state, params) => {
    const { flag } = params;
    state.noSearchResultsFlag = flag;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
