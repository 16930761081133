import axios from "axios";
import store from "../store/modules/user";
//import store from '../store/index';
import { accessToken } from "../common/auth-header"
import { refreshToken } from "../common/auth-header"
import { setRefreshToken } from "../common/auth-header";
import { setAccessToken } from "../common/auth-header";
import { RAS_URL, CID } from "./constants";

const jwtInterceptor = axios.create({});
let refreshing_token = null;

async function refresh_token() {
    console.log('Refreshing access token');
    const rt = await refreshToken();
    const at = accessToken();
    console.log(rt);
    const payload = {
        grant_type: 'refresh_token',
        access_token: at,
        refresh_token: rt,
        client_id: CID()
    };
    const refresh_url = RAS_URL();
    return axios.post(
        refresh_url,
        payload
    );
}

jwtInterceptor.interceptors.request.use((config) => {
    //const authData = store.getters["auth/getAuthData"];
    //console.log("INTERCEPTED!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    //console.log("ACCESS TOKEN:::::::::::")
    //console.log(accessToken());
    config.headers.common["Authorization"] = `Bearer ${accessToken()}`;
    //config.headers.common["x-test-header"] = 'we be here!';
    return config;
});

jwtInterceptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {

        const config = error.config;
        if (error.response && error.response.status === 401 && !config._retry) {
            config._retry = true;
            try {
                refreshing_token = refreshing_token ? refreshing_token : refresh_token();
                let res = await refreshing_token;
                refreshing_token = null;
                if(res.data.access_token) {
                    let user = JSON.parse(localStorage.getItem('user'));
                    setAccessToken(res.data.access_token);
                    await setRefreshToken(res.data.refresh_token);
                }
                error.config.headers[
                    "Authorization"
                    ] = `Bearer ${res.data.access_token}`;
                return axios(error.config);
            } catch (err) {
                return Promise.reject(err)
            }

        } else {
            return Promise.reject(error);
        }
    }
);

export default jwtInterceptor;
