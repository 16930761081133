import axios from "axios";
import qs from "qs";
import {
  RCHANS_URL,
  RCS_URL,
  RMEMS_URL,
  RRS_URL,
  buildServiceParamMap,
  assembleRallyObject,
  buildMapOfListsByAttribute,
  buildMapByValueName,
  mergeByIdMaintainOrder,
  figureOutPageNumber,
  RSENTS_URL
} from "../../common";
import { userInfo } from "../../common/user-info"
import { authHeader } from "../../common/auth-header"
import jwtInterceptor  from '../../common/jwt-interceptor';

const state = {
  ralliesByParent: {},
  allRallies: [],
  myRallies: [],
  rally_id: null,
  allRalliesByRallyId: {},
  rallyChannelsByRallyId: {},
  rallyIdsByUserId: {},
  resultCountByRallyId: {},
  showRallyInfo: false,
  maxPageByRallyId: {},
  rallySearchResults: {},
  newlyCreatedRallyId: null,
  rallyRelationshipsByCategoryId: {}
};

const getters = {
  ralliesByParent: state=> state.ralliesByParent,
  allRallies: state => state.allRallies, //sortByDate(state.allRallies, "rally", "created-at")
  myRallies: state => state.myRallies, //sortByDate(state.myRallies, "rally", "created-at"),
  rally_avatars: state => state.rally_avatars,
  rally_id: state => state.rally_id,
  allRalliesByRallyId: state  => {
    return state.allRalliesByRallyId;
  },
  rallyChannelsByRallyId: state => state.rallyChannelsByRallyId,
  rallyIdsByUserId: state=> state.rallyIdsByUserId,
  resultCountByRallyId: state=> state.resultCountByRallyId,
  showRallyInfo: state => state.showRallyInfo,
  maxPageByRallyId: state=> state.maxPageByRallyId,
  rallySearchResults: state => {
    return state.rallySearchResults;
  },
  newlyCreatedRallyId: state => state.newlyCreatedRallyId,
  rallyRelationshipsByCategoryId: state => state.rallyRelationshipsByCategoryId
};

const actions = {
  async fetchRallyGate({commit, state }, inparams){
    const {rallyId, invitationToken}  = inparams

    var jsonparams = {
      invitation_token: invitationToken
    };
    var params = buildServiceParamMap(jsonparams);
    const url = RRS_URL() + "/rallies/" + rallyId + "/gate?invitation_token=" + invitationToken;

    const response = await axios.get(url);

    if (response && response.data && response.data.data) {
      return response.data.data
    }
  },

  async fetchRalliesByParent({commit, state }, inparams){
    const {rallyId, requestedPageSize}  = inparams
    const user = userInfo();
    let pageSize = 6
    var pageNumber = figureOutPageNumber(state.maxPageByRallyId[rallyId > 0 ? rallyId: 1])
    if (requestedPageSize) {
      pageSize = requestedPageSize
    }
    var jsonparams = {
      left_rally_id: rallyId, 
      type: ['FriendlyParentChild', 'ParentChild', 'Managed', 'Open', 'HiddenOrg', 'Associated', 'ReadOnlyParentChild'],
      //include: "member_count,avatar,child_count,parent",
      "page[number]": pageNumber,
      "page[size]": pageSize,
      status: 'Active'
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RRS_URL() +"/rally-relationships" , //?id%5B%5D=38720&id%5B%5D=22041&id%5B%5D=39030&id%5B%5D=39017&id%5B%5D=38975&id%5B%5D=39019&id%5B%5D=38845&id%5B%5D=38984&id%5B%5D=38987&id%5B%5D=38738&id%5B%5D=38967&id%5B%5D=38753&include=member_count`
      params
    };
    const response = await jwtInterceptor(requestOptions);

    if (response && response.data && response.data.data && response.data.data.length > 0) {
      commit("setResultCountByRallyId", { rallyId, count: response.data.meta["total-count"] });
      var rallyObjs = assembleRallyObject("relationship", function(r) {return r.attributes["right-rally-id"]} , null, response)
      await this.dispatch("rallies/fetchRalliesWithInfo", { pageNumber, rallyObjs, parentRallyId: rallyId })
    } 
  },

  async fetchRallyRelationshipsByCategory({commit, state }, inparams){
    const { categoryId, rallyRelationshipIds }  = inparams
    const user = userInfo();
        var jsonparams = {
          id: rallyRelationshipIds,
          "page[number]": 1,
          "page[size]": "500"
        };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RRS_URL() +"/rally-relationships" , //?id%5B%5D=38720&id%5B%5D=22041&id%5B%5D=39030&id%5B%5D=39017&id%5B%5D=38975&id%5B%5D=39019&id%5B%5D=38845&id%5B%5D=38984&id%5B%5D=38987&id%5B%5D=38738&id%5B%5D=38967&id%5B%5D=38753&include=member_count`
      params
    };
    const response = await jwtInterceptor(requestOptions);
    if (response && response.data && response.data.data && response.data.data.length > 0) {
      commit("setRallyRelationshipsByCategoryId", { categoryId: categoryId, rallyRelationships: response.data.data });
      var rallyObjs = assembleRallyObject("relationship", function(r) {return r.attributes["right-rally-id"]} , null, response)
      this.dispatch("rallies/fetchRalliesWithInfo", { rallyObjs: rallyObjs })
    }
  },

  //http://localhost:3080/api/v1/rallies.json?include=avatar%2Cchild_count%2Cmember_count&page%5Bnumber%5D=1&page%5Bsize%5D=200&query=test&user_id=303137 
  async searchRallies({commit, state }, inparams){
    const {query}  = inparams
    const user = userInfo();
    var jsonparams = {
      query,
      include: "member_count,avatar,child_count,parent",
      rally_type: ['Rally', 'Standard'],
      "page[number]": 1,
      "page[size]": "200",
      user_id: user.user_id ,
      status: 'Active'
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RRS_URL() + "/rallies", //?id%5B%5D=38720&id%5B%5D=22041&id%5B%5D=39030&id%5B%5D=39017&id%5B%5D=38975&id%5B%5D=39019&id%5B%5D=38845&id%5B%5D=38984&id%5B%5D=38987&id%5B%5D=38738&id%5B%5D=38967&id%5B%5D=38753&include=member_count`
      params
    };
    const response = await jwtInterceptor(requestOptions);
    if (response && response.data && response.data.data && response.data.data.length > 0) {
      //commit("setResultCountByRallyId", { rallyId, count: response.data.meta["total-count"] });
      var rallyObjs = assembleRallyObject("rally", function(r) {return r.id}, null, response) 
      commit("setRallySearchResults", { rallies: Object.values(rallyObjs) }); 
      this.dispatch("contents/setIncludedById", {included: response.data.included, avatarType: "rally"})
      this.dispatch("rallies/fetchRalliesChannels", { pageNumber : 1, rallyObjs, parentRallyId : 0 });
    } else {
      this.dispatch('user/flashSuccess', 'No results found!');
    }
  },

  async searchChildRallies({commit, state }, inparams){
    const {parent_rally_id, query}  = inparams
    const user = userInfo();
    let title = "%" + query + "%";
    var jsonparams = {
      title,
      a_relation_id: parent_rally_id,
      include: "member_count,avatar,child_count,parent",
      rally_type: ['Rally', 'Standard'],
      "page[number]": 1,
      "page[size]": "200",
      user_id: user.user_id ,
      status: 'Active'
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RRS_URL() + "/rallies", 
      params
    };
    const response = await jwtInterceptor(requestOptions);
    if (response && response.data && response.data.data && response.data.data.length > 0) {
      //commit("setResultCountByRallyId", { rallyId, count: response.data.meta["total-count"] });
      var rallyObjs = assembleRallyObject("rally", function(r) {return r.id}, null, response)
      commit("setRallySearchResults", { rallies: Object.values(rallyObjs) });
      this.dispatch("contents/setIncludedById", {included: response.data.included, avatarType: "rally"})
      this.dispatch("rallies/fetchRalliesChannels", { pageNumber : 1, rallyObjs, parentRallyId : 0 });
    } else {
      this.dispatch('user/flashSuccess', 'No results found!');
    }
  },

  // DEPRECATED - use fetchMyRalliesWithInfo. Leaving this here to not break anything
  // The problem is this method is used in multiple places and can result on a user opening a rally that they aren't a member of
  // and having it then appear on their dashboard
  async fetchRalliesWithInfo({ commit, state }, inparams) {
    const user = userInfo();

    var {rallyObjs, pageNumber, parentRallyId, rallyId, rallyIds} = inparams;
    // "/api/v1/memberships.json?include_admin_archived=true&page%5Bnumber%5D=1&page%5Bsize%5D=12&rally_type%5B%5D=Rally&rally_type%5B%5D=Standard&role%5B%5D=Admin&role%5B%5D=Friend&role%5B%5D=Subscriber&role%5B%5D=Owner&sort=archived%2C+sort_order%
    var jsonparams = {
      //include_admin_archived: "false",
      rally_type: [
          "Rally",
        "Standard",
        "Basic",
        "Legacy",
        "Interim"
      ],
      role: ["Owner", "Subscriber", "Admin", "Friend"],
      include: "membership_profile",
      include_admin_archived: true,
      sort: 'archived, title',
      status: ["Active", "Invited"]
    };

    if (rallyId) {
      jsonparams["rally_id"] = rallyId
      if (userInfo()){
        jsonparams["user_id"] = userInfo().id
      }
    }else if (rallyObjs){
      jsonparams["rally_id"] = Object.keys(rallyObjs).map(o => {return parseInt(o)})
      jsonparams["user_id"]= user.user_id
    } else if (rallyIds) {
      jsonparams["rally_id"] = rallyIds
      jsonparams["user_id"]= user.user_id
    } else {
      pageNumber = figureOutPageNumber(state.maxPageByRallyId[1])
      jsonparams["page[number]"] = pageNumber
      jsonparams["page[size]"] = "20"
      jsonparams["user_id"]= user.user_id 
    }

    var params = buildServiceParamMap(jsonparams);
    //params.append("sort", "archived%2C+sort_order%2C+title");???
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RMEMS_URL(), //?include_admin_archived=true&page%5Bnumber%5D=1&page%5Bsize%5D=12&rally_type%5B%5D=Rally&rally_type%5B%5D=Standard&role%5B%5D=Admin&role%5B%5D=Friend&role%5B%5D=Subscriber&role%5B%5D=Owner&sort=archived%2C+sort_order%2C+title&status%5B%5D=Active&status%5B%5D=Invited&user_id=303137
      params: params
    };
    try {
      const response = await jwtInterceptor(requestOptions);
      if (!parentRallyId || parentRallyId < 2){
        await commit("setResultCountByRallyId", { rallyId: 1, count: response.data.meta["total-count"] });
      }
      if (response && response.data && response.data.data && response.data.data.length > 0) {
        rallyObjs = await assembleRallyObject("membership", function(r) {return r.attributes["rally-id"]} , rallyObjs, response)
      } 
    } catch (e){
      console.log("rally membership error...")
      console.trace()
      console.log(e)
    }
    //we cannot assume that there will be any members...
    if (!rallyObjs && rallyId){
      const rid = rallyId
      //relationship?.attributes["left-rally-id"]]?.id;
      let sr = {id: rid, rally: {  attributes:{}, relationships:{avatar:{data: { id:-1 }}}, membership:{relationships:{}}}}
      if (parentRallyId){
        sr.rally["relationship"] = {attributes:{"left-rally-id": parentRallyId}}
      }
      rallyObjs = {}
      rallyObjs[rallyId] = sr
    }

    if (rallyObjs) {
      await this.dispatch("rallies/fetchRalliesByIds", { pageNumber, rallyObjs , parentRallyId, rallyId })
    } else {
      await commit("setRallies", []);
    }
  },

  async fetchMyRalliesWithInfo({ commit, state }, inparams) {
    const user = userInfo();

    var {rallyObjs, pageNumber, parentRallyId, rallyId, rallyIds} = inparams;
    // "/api/v1/memberships.json?include_admin_archived=true&page%5Bnumber%5D=1&page%5Bsize%5D=12&rally_type%5B%5D=Rally&rally_type%5B%5D=Standard&role%5B%5D=Admin&role%5B%5D=Friend&role%5B%5D=Subscriber&role%5B%5D=Owner&sort=archived%2C+sort_order%
    var jsonparams = {
      //include_admin_archived: "false",
      rally_type: [
        "Rally",
        "Standard",
        "Basic",
        "Legacy",
        "Interim"
      ],
      role: ["Owner", "Subscriber", "Admin", "Friend"],
      include: "membership_profile",
      include_admin_archived: true,
      sort: 'archived, title',
      status: ["Active", "Invited"]
    };

    if (rallyId) {
      jsonparams["rally_id"] = rallyId
      jsonparams["user_id"] = userInfo().id
    }else if (rallyObjs){
      jsonparams["rally_id"] = Object.keys(rallyObjs).map(o => {return parseInt(o)})
      jsonparams["user_id"]= user.user_id
    } else if (rallyIds) {
      jsonparams["rally_id"] = rallyIds
      jsonparams["user_id"]= user.user_id
    } else {
      pageNumber = figureOutPageNumber(state.maxPageByRallyId[1])
      jsonparams["page[number]"] = pageNumber
      jsonparams["page[size]"] = "20"
      jsonparams["user_id"]= user.user_id
    }

    var params = buildServiceParamMap(jsonparams);
    //params.append("sort", "archived%2C+sort_order%2C+title");???
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RMEMS_URL(), //?include_admin_archived=true&page%5Bnumber%5D=1&page%5Bsize%5D=12&rally_type%5B%5D=Rally&rally_type%5B%5D=Standard&role%5B%5D=Admin&role%5B%5D=Friend&role%5B%5D=Subscriber&role%5B%5D=Owner&sort=archived%2C+sort_order%2C+title&status%5B%5D=Active&status%5B%5D=Invited&user_id=303137
      params: params
    };
    try {
      const response = await jwtInterceptor(requestOptions);
      if (!parentRallyId || parentRallyId < 2){
        await commit("setResultCountByRallyId", { rallyId: 1, count: response.data.meta["total-count"] });
      }
      if (response && response.data && response.data.data && response.data.data.length > 0) {
        rallyObjs = await assembleRallyObject("membership", function(r) {return r.attributes["rally-id"]} , rallyObjs, response)
      }
    } catch (e){
      console.log("rally membership error...")
      console.trace()
      console.log(e)
    }
    //we cannot assume that there will be any members...
    if (!rallyObjs && rallyId){
      const rid = rallyId
      //relationship?.attributes["left-rally-id"]]?.id;
      let sr = {id: rid, rally: {  attributes:{}, relationships:{avatar:{data: { id:-1 }}}, membership:{relationships:{}}}}
      if (parentRallyId){
        sr.rally["relationship"] = {attributes:{"left-rally-id": parentRallyId}}
      }
      rallyObjs = {}
      rallyObjs[rallyId] = sr
    }

    if (rallyObjs) {
      await this.dispatch("rallies/fetchMyRalliesByIds", { pageNumber, rallyObjs , parentRallyId, rallyId })
    } else {
      await commit("setRallies", []);
    }
  },

  async fetchMyRalliesByIds({ commit, state }, inparams) {

    var { pageNumber, rallyObjs, parentRallyId, rallyId } = inparams;
    var rallyIds = Object.keys(rallyObjs)
    const user = userInfo();
    var jsonparams = {
      include: "member_count,avatar,child_count,parent",
      id: rallyIds,
      status: ['Active', 'Archived'],
      sort: 'title',
      "page[number]": "1",
      "page[size]": "100"
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RRS_URL() + "/rallies", //?id%5B%5D=38720&id%5B%5D=22041&id%5B%5D=39030&id%5B%5D=39017&id%5B%5D=38975&id%5B%5D=39019&id%5B%5D=38845&id%5B%5D=38984&id%5B%5D=38987&id%5B%5D=38738&id%5B%5D=38967&id%5B%5D=38753&include=member_count`
      params
    };
    const response = await jwtInterceptor(requestOptions);
    rallyObjs = assembleRallyObject("rally", function(r) {return r.id}, rallyObjs, response)
    this.dispatch("contents/setIncludedById", {included: response.data.included, avatarType: "rally"})
    //speeding up by not loading channels
    var rallies = []
    for (var prop in rallyObjs) {
      rallies.push(rallyObjs[prop])
    }
    if (parentRallyId && parentRallyId > 0){
      commit("setRalliesByParent", {pageNumber, parentRallyId, rallies})
    } else {
      commit("setMyRallies", {pageNumber, rallies});
      commit("setRallies", {pageNumber, rallies});
    }
    //if (rallyId){
    this.dispatch("rallies/fetchRalliesChannels", { pageNumber, rallyObjs, parentRallyId, rallyId });
    //}
  },

  async fetchRalliesByIds({ commit, state }, inparams) {

    var { pageNumber, rallyObjs, parentRallyId, rallyId } = inparams;
    var rallyIds = Object.keys(rallyObjs)
    const user = userInfo();
    var jsonparams = {
      include: "member_count,avatar,child_count,parent",
      id: rallyIds,
      status: ['Active', 'Archived'],
      sort: 'title',
      "page[number]": "1",
      "page[size]": "20"
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RRS_URL() + "/rallies", //?id%5B%5D=38720&id%5B%5D=22041&id%5B%5D=39030&id%5B%5D=39017&id%5B%5D=38975&id%5B%5D=39019&id%5B%5D=38845&id%5B%5D=38984&id%5B%5D=38987&id%5B%5D=38738&id%5B%5D=38967&id%5B%5D=38753&include=member_count`
      params
    };
    const response = await jwtInterceptor(requestOptions);
    rallyObjs = assembleRallyObject("rally", function(r) {return r.id}, rallyObjs, response)
    this.dispatch("contents/setIncludedById", {included: response.data.included, avatarType: "rally"})
    //speeding up by not loading channels
    var rallies = []
    for (var prop in rallyObjs) {
      rallies.push(rallyObjs[prop])
    }
    if (parentRallyId && parentRallyId > 0){
      await commit("setRalliesByParent", {pageNumber, parentRallyId, rallies})
    } else {
      await commit("setRallies", {pageNumber, rallies});
    }
    //if (rallyId){
      await this.dispatch("rallies/fetchRalliesChannels", { pageNumber, rallyObjs, parentRallyId, rallyId });
    //}
  },


  async fetchRalliesChannels({commit, state}, inparams){
    const user = userInfo();
    var rallyIds = [inparams.rallyId];
    var { pageNumber, rallyObjs, parentRallyId} = inparams;
    if (rallyObjs){
       rallyIds = Object.keys(rallyObjs)
    }

    var jsonparams = {
      rally_id: rallyIds,
      default: true,
      "ne[type]": ['RallySettings'],
      sort: "sort_order",
      "page[number]": "1",
      "page[size]": "3000"
    };
    var params = buildServiceParamMap(jsonparams);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      url: RCHANS_URL(),
      params
    };
    const response = await jwtInterceptor(requestOptions);
    var rChanMap = buildMapOfListsByAttribute( response.data.data, "rally-id")
    var byIdMap = buildMapByValueName("id", response.data.data)
    commit("setRallyChannelsByRallyId", rChanMap)
    this.dispatch("channels/setChannelsById", { byIdMap })
    var rallies = []
    for (var prop in rallyObjs) {
      rallies.push(rallyObjs[prop])
    }
    if (parentRallyId && parentRallyId > 0){
      commit("setRalliesByParent", {pageNumber, parentRallyId, rallies})
    } else {
      commit("setRallies", {pageNumber, rallies});
    }
  },

 async createRally({ commit, state}, inparams) {
  const { avatarId, privacy, title, description, type, vertical, council, parent_id} = inparams;
  const user = userInfo();
  const createdAttributes = {
      avatar_id: avatarId,
      created_by: user.user_id,
      creator_id: user.user_id, 
      created_via: "NewRally",
      privacy,
      title,
      description,
      type,
      vertical,
      council,
      parent_id
  };
  const requestOptions = {
    method: "POST",
    url: RRS_URL() + "/rallies",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      data: {
        type: "rallies",
        attributes: createdAttributes
      }
    }
  };
  const response = await jwtInterceptor(requestOptions);
  //this.rally_id = response.data.data.id;
   commit("setNewlyCreatedRallyId", response.data.data.id);
  this.dispatch("rallies/fetchRalliesWithInfo", { rallyId: response.data.data.id });
},
  async deleteRally({ commit, state }, inparams) {
    const { rallyId } = inparams;
    const user = userInfo();
    const atts = {
      rally_delete: 'YES'
    };
    const requestOptions = {
      method: "DELETE",
      url: RRS_URL() + "/rallies/" + rallyId + ".json",
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "rallies",
          attributes: atts
        }
      }
    };

    const response = await jwtInterceptor(requestOptions);
    this.dispatch("rallies/fetchRalliesWithInfo", {  });
  },

  async updateRally({ commit, state }, inparams) {
    const { rally, privacy, status, disable_notify_immediate, description, invitation_message } = inparams;
    const user = userInfo();
    const createdAttributes = {
        id: rally.rally.id,
    };

    if (rally.rally.attributes.title){
      createdAttributes['title'] = rally.rally.attributes.title
    }
    if (privacy){
      createdAttributes['privacy'] = privacy
    }
    if (status){
      createdAttributes['status'] = status
    }
    if (disable_notify_immediate){
      createdAttributes['disable_notify_immediate'] = disable_notify_immediate
    }
    if (description){
      createdAttributes['description'] = description
    }
    if (invitation_message){
      createdAttributes['invitation_message'] = invitation_message
    }

    const requestOptions = {
      method: "PUT",
      url: RRS_URL() + "/rallies/" + rally.rally.id,
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        data: {
          type: "rallies",
          attributes: createdAttributes
        }
      }
    };
    const response = await jwtInterceptor(requestOptions);
  },


  async clearRallyState({ commit, state }) {
    commit("clearState")
  },

  async clearRallySearchResults({commit, state}){
    commit("clearRallySearchResults")
  },
  closeRallyInfo: ({commit}) => {
    commit('setRallyInfo', false);
  },
  toggleRallyInfo: ({commit}) => {
    const showRallyInfo = state.showRallyInfo;
    commit('setRallyInfo', !showRallyInfo);
  },

};

const mutations = {


  setResultCountByRallyId: (state, params) =>{
    const {rallyId, count} = params;
    //console.log("rallies: setting result count to : " + rallyId + " " + count)
    var newMap = {};
    newMap[rallyId]= count
    state.resultCountByRallyId = {...state.resultCountByRallyId, ...newMap}
  },

  setRallyRelationshipsByCategoryId: (state, params) => {
    const {categoryId, rallyRelationships} = params;
    var newMap = {};
    newMap[categoryId]= rallyRelationships
    state.rallyRelationshipsByCategoryId = {...state.rallyRelationshipsByCategoryId, ...newMap}
  },

  setRalliesByParent: (state, params) => {
    const { pageNumber, parentRallyId, rallies, } = params
    var newMap = {} 
    var oldList = state.ralliesByParent[parentRallyId]
    var deduped = mergeByIdMaintainOrder(oldList ? oldList : [], rallies, "id")
    newMap[parentRallyId] =  deduped
    state.ralliesByParent = {...state.ralliesByParent, ...newMap}
    var newMapOfRallies = rallies.reduce(function(result, currRally) {
      result[currRally.id] = currRally;
      return result;
    }, {});
    state.allRalliesByRallyId = { ...state.allRalliesByRallyId, ...newMapOfRallies };

    //track pagination numbers
    newMap = {};
    var calledPages = state.maxPageByRallyId[parentRallyId]
    if (!calledPages){
      calledPages = []
    }
    calledPages[pageNumber] = "called"
    newMap[parentRallyId]= calledPages
    state.maxPageByRallyId = {...state.maxPageByRallyId, ...newMap}

  },

  setMyRallies: (state, params) => {
    const { pageNumber, rallies } = params
    var newMyRallies = mergeByIdMaintainOrder(state.myRallies, rallies, "id")
    state.myRallies = [ ...newMyRallies]
    var newMapOfRallies = newMyRallies.reduce(function(result, currRally) {
      result[currRally.id] = currRally;
      return result;
    }, {});
    state.allRalliesByRallyId = { ...state.allRalliesByRallyId, ...newMapOfRallies };

    //track pagination numbers
    var newMap = {};
    var calledPages = state.maxPageByRallyId[1]
    if (!calledPages){
      calledPages = []
    }
    calledPages[pageNumber] = "called"
    newMap[1]= calledPages
    state.maxPageByRallyId = {...state.maxPageByRallyId, ...newMap}
  },

  setRallies: (state, params) => {
    const { pageNumber, rallies } = params
    /*let withParents = [...rallies]
    if (rallies && rallies.forEach){
      rallies.forEach(r => {
        console.log("rallies....")
        console.log(r)
        if (r && r.rally && r.rally.attributes && r.rally.attributes.parent){
          console.log("adding parent....")
          console.log(r)
          withParents.push({id: r.rally.attributes.parent.id, rally: r.rally.attributes.parent})
        }
      });
    }*/
    var newAllRallies = mergeByIdMaintainOrder(state.allRallies, rallies, "id")
    state.allRallies = [ ...newAllRallies]
    var newMapOfRallies = newAllRallies.reduce(function(result, currRally) {
      result[currRally.id] = currRally;
      return result;
    }, {});
    state.allRalliesByRallyId = { ...state.allRalliesByRallyId, ...newMapOfRallies };
    
    //track pagination numbers
    var newMap = {};
    var calledPages = state.maxPageByRallyId[1]
    if (!calledPages){
      calledPages = []
    }
    calledPages[pageNumber] = "called"
    newMap[1]= calledPages
    state.maxPageByRallyId = {...state.maxPageByRallyId, ...newMap}
  },

  setRallyId: (state, id) => (state.message_id = id),
  setRallyAvatars: (state, avatars) => (state.avatars = avatars),
  clearState: (state) => {
    state.allRallies = []
    state.myRallies = []
    state.rally_id = null
    state.resultCountByRallyId = {}
    state.maxPageByRallyId = {};
    state.rallySearchResults = [];
  },

  clearRallySearchResults: (state) => {
    state.rallySearchResults = [];
  },
  setNewlyCreatedRallyId: (state, params) => {
    const rallyId = params;
    state.newlyCreatedRallyId = rallyId;
  },
  setRallyChannelsByRallyId: (state, rChanMap) => {
    state.rallyChannelsByRallyId = { ...state.rallyChannelsByRallyId, ...rChanMap}
  },
  setRallyIdsByUserId: (state, params) =>{
    const {userId, rallyIds} = params 
    var newMap = {}
    newMap[userId] = rallyIds
    state.rallyIdsByUserId = {...state.rallyIdsByUserId, ...newMap}
  },
  setRallySearchResults: (state, params) => {
    const { rallies } = params;
    state.rallySearchResults = rallies
  },
  setRallyInfo: (state, status) => {
    state.showRallyInfo = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
